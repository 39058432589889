<template>
    <div :class="{ level: orientation == 'horizontal' }">
        <div class="has-text-left is-size-7">
            {{ $t(`${item.location}.${item.name}`) }}
        </div>
        <div
            v-if="item.type !== 'custom_parcel'"
            class="has-text-weight-bold is-size-7"
            :class="{
                'has-text-black': !get_value_text(item).includes('null'),
                'has-text-danger': get_value_text(item).includes('null'),
                'has-text-right': orientation === 'horizontal',
                'has-text-left': orientation === 'vertical',
            }"
        >
            {{ get_value_text(item) }}
        </div>
        <div
            v-if="item.type === 'custom_parcel'"
            class="has-text-weight-bold has-text-black is-size-7"
            :class="{
                'has-text-right': orientation === 'horizontal',
                'has-text-left': orientation === 'vertical',
            }"
        >
            <p
                v-for="(value, index) in get_value_from_store('features.parcel_ids')"
                :key="index"
                class="mb-0 paragraph"
            >
                {{ get_value_text(item, index) }}
            </p>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
    name: 'SectionItem',
    props: {
        item: { type: Object, required: true },
        orientation: { type: String, default: 'horizontal' },
    },
    computed: {
        ...mapGetters(['getFullStore', 'getFeatures', 'getValuation']),
        parcel_areas() {
            let parcel_geojson = this.getFullStore.map_scene2d.parcel_geojson
            let parcels = this.getFeatures.parcel_ids.map((id) =>
                parcel_geojson.features.find(
                    (parcel) => parcel.properties.parcel_id === id
                )
            )

            return parcels.map((p) => p.properties.parcel_area)
        },
    },
    methods: {
        get_value_from_store(location, name) {
            let splits = `${location}.${name}`.split('.')
            let search_dict = { features: this.getFeatures, valuation: this.getValuation } //this.getFullStore.Address.data
            for (var i = 0; i < splits.length; i++) {
                if (typeof search_dict[splits[i]] === 'undefined') {
                    return null
                } else if (i < splits.length - 1) {
                    search_dict = search_dict[splits[i]]
                } else {
                    return search_dict[splits[i]]
                }
            }
        },
        get_value_text(item, index) {
            if (item.type == 'boolean') {
                if (this.get_value_from_store(item.location, item.name) === null) {
                    return `null (${this.$t('value.yes')})`
                } else {
                    return this.$t(
                        'value.' +
                            (this.get_value_from_store(item.location, item.name)
                                ? 'yes'
                                : 'no')
                    )
                }
            } else if (item.type == 'string') {
                return this.get_value_from_store(item.location, item.name)
            } else if (item.type == 'enum') {
                if (this.get_value_from_store(item.location, item.name) === null) {
                    return `null (${this.$t('value.' + item.values[0])})`
                } else {
                    // TODO: get rid of the LowerCase workaround (e.g. for flood risk)
                    return this.$t(
                        'value.' +
                            this.get_value_from_store(
                                item.location,
                                item.name
                            ).toLowerCase()
                    )
                }
            } else if (item.type == 'fixed') {
                return this.$t('value.' + item.values[0])
            } else if (item.type == 'custom_parcel') {
                let parcel_ids = this.get_value_from_store('features.parcel_ids')
                let parcel_area_string = this.parcel_areas[index].toLocaleString(
                    undefined,
                    {
                        maximumFractionDigits: 0,
                    }
                )
                return `${parcel_ids[index]} (${parcel_area_string} m²)`
            } else {
                let unit_text = ''
                let value = this.get_value_from_store(item.location, item.name)
                if (item.unit == 'm') {
                    unit_text = ' ' + this.$t('unit.' + item.unit)
                } else if (item.unit == 'm2') {
                    if (value !== null) {
                        value = value.toLocaleString(undefined, {
                            maximumFractionDigits: 0,
                        })
                    }
                    unit_text = ' ' + this.$t('unit.' + item.unit)
                } else if (item.unit == 'm3') {
                    unit_text = ' ' + this.$t('unit.' + item.unit)
                } else if (item.unit == 'degree') {
                    unit_text = '°'
                } else if (item.unit == 'percent') {
                    if (value != null) {
                        value = value.toLocaleString(undefined, {
                            style: 'percent',
                            maximumFractionDigits: 0,
                        })
                    }
                    unit_text = ''
                } else if (item.unit == 'kwh/m2year') {
                    unit_text = ' ' + this.$t('unit.' + item.unit)
                }
                if (value !== null) {
                    return value.toString() + unit_text
                } else {
                    return 'null' + unit_text
                }
            }
        },
    },
}
</script>

<style>
.has-text-secondary {
    color: #ff6f00;
}
</style>

export const config = [
    {
        report_scope: ['core', 'avm', 'dvm', 'ovm'],
        building_type_scope: ['house', 'apartment'],
        functionality: 'feature',
        type: 'string',
        unit: '',
        location: 'address_text',
        name: 'full_address',
        icon: '',
        fallback: '',
        values: null,
        sections: [],
    },
    {
        report_scope: ['avm', 'dvm', 'ovm'],
        building_type_scope: ['house', 'apartment'],
        functionality: 'feature',
        type: 'date',
        unit: '',
        location: 'valuation',
        name: 'date',
        icon: '',
        fallback: '',
        values: null,
        sections: [],
    },
    {
        report_scope: ['avm', 'dvm', 'ovm'],
        building_type_scope: ['house', 'apartment'],
        functionality: 'feature',
        type: 'enum',
        unit: '',
        location: 'valuation',
        name: 'type',
        icon: '',
        fallback: '',
        values: ['avm', 'dvm', 'ovm'],
        sections: [],
    },
    {
        report_scope: ['avm', 'dvm', 'ovm'],
        building_type_scope: ['house', 'apartment'],
        functionality: 'feature',
        type: 'string',
        unit: '',
        location: 'request',
        name: 'valuation_request_ref',
        icon: '',
        fallback: '',
        values: null,
        sections: [],
    },
    {
        report_scope: ['core'],
        building_type_scope: ['house', 'apartment'],
        functionality: 'text',
        type: 'string',
        unit: '',
        location: 'section',
        name: 'reconstruction_value',
        icon: 'ic-reconstruction',
        fallback: '',
        values: null,
        sections: [],
    },
    {
        report_scope: ['core'],
        building_type_scope: ['house', 'apartment'],
        functionality: 'text',
        type: 'string',
        unit: '',
        location: 'section',
        name: 'address_location',
        icon: 'ic-location',
        fallback: '',
        values: null,
        sections: [],
    },
    {
        report_scope: ['core'],
        building_type_scope: ['house', 'apartment'],
        functionality: 'text',
        type: 'string',
        unit: '',
        location: 'section',
        name: 'secondary_buildings',
        icon: 'ic-cube',
        fallback: '',
        values: null,
        sections: [],
    },
    {
        report_scope: ['core'],
        building_type_scope: ['house', 'apartment'],
        functionality: 'text',
        type: 'string',
        unit: '',
        location: 'section',
        name: 'economic_activity',
        icon: 'ic-economic',
        fallback: '',
        values: null,
        sections: [],
    },
    {
        report_scope: ['core'],
        building_type_scope: ['house', 'apartment'],
        functionality: 'text',
        type: 'string',
        unit: '',
        location: 'section',
        name: 'roof',
        icon: 'ic-roof',
        fallback: '',
        values: null,
        sections: [],
    },
    {
        report_scope: ['core'],
        building_type_scope: ['house', 'apartment'],
        functionality: 'text',
        type: 'string',
        unit: '',
        location: 'section',
        name: 'warnings',
        icon: '',
        fallback: '',
        values: null,
        sections: [],
    },
    {
        report_scope: ['avm', 'dvm', 'ovm'],
        building_type_scope: ['house', 'apartment'],
        functionality: 'feature',
        type: 'string',
        unit: '',
        location: 'request',
        name: 'bank',
        icon: '',
        fallback: '',
        values: null,
        sections: [],
    },
    {
        report_scope: ['dvm', 'ovm'],
        building_type_scope: ['house', 'apartment'],
        functionality: 'feature',
        type: 'string',
        unit: '',
        location: 'valuation',
        name: 'valuer',
        icon: '',
        fallback: '',
        values: null,
        sections: [],
    },
    {
        report_scope: ['avm', 'dvm', 'ovm'],
        building_type_scope: ['house', 'apartment'],
        functionality: 'feature',
        type: 'string',
        unit: '',
        location: 'request',
        name: 'customer',
        icon: '',
        fallback: '',
        values: null,
        sections: [],
    },
    {
        report_scope: ['avm', 'dvm', 'ovm'],
        building_type_scope: ['house', 'apartment'],
        functionality: 'feature',
        type: 'enum',
        unit: '',
        location: 'request',
        name: 'purpose',
        icon: '',
        fallback: '',
        values: ['new_loan', 'revaluation'],
        sections: [],
    },
    {
        report_scope: ['avm', 'dvm', 'ovm'],
        building_type_scope: ['house', 'apartment'],
        functionality: 'text',
        type: 'string',
        unit: '',
        location: 'section',
        name: 'general_info',
        icon: '',
        fallback: '',
        values: null,
        sections: [],
    },
    {
        report_scope: ['core', 'avm', 'dvm', 'ovm'],
        building_type_scope: ['house', 'apartment'],
        functionality: 'feature',
        type: 'enum',
        unit: '',
        location: 'features',
        name: 'f_building_type',
        icon: '',
        fallback: '',
        values: ['house', 'apartment'],
        sections: ['general_info', 'building', 'c_building'],
    },
    {
        report_scope: ['core', 'avm', 'dvm', 'ovm'],
        building_type_scope: ['house', 'apartment'],
        functionality: 'feature',
        type: 'enum',
        unit: '',
        location: 'features',
        name: 'f_number_of_facades',
        icon: '',
        fallback: '',
        values: ['attached', 'semi', 'detached'],
        sections: ['general_info', 'c_building'],
    },
    {
        report_scope: ['core', 'avm', 'dvm', 'ovm'],
        building_type_scope: ['house'],
        functionality: 'feature',
        type: 'float',
        unit: 'm2',
        location: 'features',
        name: 'f_parcel_area',
        icon: '',
        fallback: '',
        values: null,
        sections: ['parcels_and_buildings', 'parcels', 'c_parcels'],
    },
    {
        report_scope: ['avm', 'dvm', 'ovm'],
        building_type_scope: ['house', 'apartment'],
        functionality: 'text',
        type: 'string',
        unit: '',
        location: 'section',
        name: 'parcels_and_buildings',
        icon: '',
        fallback: '',
        values: null,
        sections: [],
    },
    {
        report_scope: ['core'],
        building_type_scope: ['house', 'apartment'],
        functionality: 'text',
        type: 'string',
        unit: '',
        location: 'section',
        name: 'c_parcels',
        icon: 'ic-environment',
        fallback: '',
        values: null,
        sections: [],
    },
    {
        report_scope: ['core'],
        building_type_scope: ['house', 'apartment'],
        functionality: 'text',
        type: 'string',
        unit: '',
        location: 'section',
        name: 'c_building',
        icon: 'ic-building',
        fallback: '',
        values: null,
        sections: [],
    },
    {
        report_scope: ['core', 'avm', 'dvm', 'ovm'],
        building_type_scope: ['house', 'apartment'],
        functionality: 'feature',
        type: 'float',
        unit: 'm2',
        location: 'features',
        name: 'f_building_area',
        icon: '',
        fallback: '',
        values: null,
        sections: ['parcels_and_buildings', 'c_building'],
    },
    {
        report_scope: ['core', 'avm', 'dvm', 'ovm'],
        building_type_scope: ['house'],
        functionality: 'feature',
        type: 'int',
        unit: '',
        location: 'features',
        name: 'f_x_annexes',
        icon: '',
        fallback: '',
        values: null,
        sections: ['parcels_and_buildings', 'parcels', 'secondary_buildings'],
    },
    {
        report_scope: ['core', 'avm', 'dvm', 'ovm'],
        building_type_scope: ['house'],
        functionality: 'feature',
        type: 'float',
        unit: 'm2',
        location: 'features',
        name: 'f_annexes_area',
        icon: '',
        fallback: '',
        values: null,
        sections: ['parcels_and_buildings', 'parcels', 'secondary_buildings'],
    },
    {
        report_scope: ['core', 'avm', 'dvm', 'ovm'],
        building_type_scope: ['house'],
        functionality: 'feature',
        type: 'float',
        unit: 'm2',
        location: 'features',
        name: 'f_area_largest_annex',
        icon: '',
        fallback: '',
        values: null,
        sections: ['secondary_buildings'],
    },
    {
        report_scope: ['core', 'avm', 'dvm', 'ovm'],
        building_type_scope: ['house'],
        functionality: 'feature',
        type: 'custom_parcel',
        unit: '',
        location: 'features',
        name: 'parcel_ids',
        icon: '',
        fallback: '',
        values: null,
        sections: ['parcels_and_buildings'],
    },
    {
        report_scope: ['core', 'avm', 'dvm', 'ovm'],
        building_type_scope: ['house', 'apartment'],
        functionality: 'feature',
        type: 'float',
        unit: 'degree',
        location: 'features',
        name: 'f_lng',
        icon: '',
        fallback: '',
        values: null,
        sections: ['address_location'],
    },
    {
        report_scope: ['core', 'avm', 'dvm', 'ovm'],
        building_type_scope: ['house', 'apartment'],
        functionality: 'feature',
        type: 'float',
        unit: 'degree',
        location: 'features',
        name: 'f_lat',
        icon: '',
        fallback: '',
        values: null,
        sections: ['address_location'],
    },
    {
        report_scope: ['core'],
        building_type_scope: ['house', 'apartment'],
        functionality: 'feature',
        type: 'int',
        unit: '',
        location: 'features',
        name: 'f_number_of_addresses',
        icon: '',
        fallback: '',
        values: null,
        sections: ['address_location'],
    },
    {
        report_scope: ['core', 'avm', 'dvm', 'ovm'],
        building_type_scope: ['house', 'apartment'],
        functionality: 'text',
        type: 'string',
        unit: '',
        location: 'section',
        name: 'building',
        icon: '',
        fallback: '',
        values: null,
        sections: [],
    },
    {
        report_scope: ['dvm', 'ovm'],
        building_type_scope: ['house', 'apartment'],
        functionality: 'feature',
        type: 'float',
        unit: 'm2',
        location: 'features',
        name: 'f_living_area',
        icon: '',
        fallback: '',
        values: null,
        sections: ['building'],
    },
    {
        report_scope: ['dvm', 'ovm'],
        building_type_scope: ['house', 'apartment'],
        functionality: 'feature',
        type: 'enum',
        unit: '',
        location: 'dvm_features',
        name: 'style_of_house',
        icon: '',
        fallback: '',
        values: ['traditional', 'modern', 'contemporary', 'mediterranean', 'other'],
        sections: ['building'],
    },
    {
        report_scope: ['dvm', 'ovm'],
        building_type_scope: ['house', 'apartment'],
        functionality: 'feature',
        type: 'float',
        unit: '',
        location: 'features',
        name: 'f_construction_year',
        icon: '',
        fallback: '',
        values: null,
        sections: ['building'],
    },
    {
        report_scope: ['dvm', 'ovm'],
        building_type_scope: ['house', 'apartment'],
        functionality: 'feature',
        type: 'numeric or string',
        unit: '',
        location: 'features',
        name: 'f_epc',
        icon: '',
        fallback: '',
        values: null,
        sections: [],
    },
    {
        report_scope: ['dvm', 'ovm'],
        building_type_scope: ['house', 'apartment'],
        functionality: 'feature',
        type: 'enum',
        unit: '',
        location: 'features',
        name: 'f_epc_label',
        icon: '',
        fallback: '',
        values: ['A++', 'A+', 'A', 'B', 'C', 'D', 'E', 'F', 'G'],
        sections: [],
    },
    {
        report_scope: ['dvm', 'ovm'],
        building_type_scope: ['house', 'apartment'],
        functionality: 'feature',
        type: 'numeric',
        unit: 'kwh/m2year',
        location: 'features',
        name: 'f_epc_numeric',
        icon: '',
        fallback: '',
        values: null,
        sections: ['building'],
    },
    {
        report_scope: ['dvm', 'ovm'],
        building_type_scope: ['apartment'],
        functionality: 'feature',
        type: 'int',
        unit: '',
        location: 'features',
        name: 'f_top_floor',
        icon: '',
        fallback: '',
        values: null,
        sections: ['building'],
    },
    {
        report_scope: ['dvm', 'ovm'],
        building_type_scope: ['apartment'],
        functionality: 'feature',
        type: 'array_of_int',
        unit: '',
        location: 'features',
        name: 'f_floors',
        icon: '',
        fallback: '',
        values: null,
        sections: ['building'],
    },
    {
        report_scope: ['dvm', 'ovm'],
        building_type_scope: ['apartment'],
        functionality: 'feature',
        type: 'boolean',
        unit: '',
        location: 'features',
        name: 'f_balcony_present',
        icon: '',
        fallback: '',
        values: null,
        sections: ['building'],
    },
    {
        report_scope: ['dvm', 'ovm'],
        building_type_scope: ['apartment'],
        functionality: 'feature',
        type: 'int',
        unit: '',
        location: 'features',
        name: 'f_n_closed_garage',
        icon: '',
        fallback: '',
        values: null,
        sections: ['building'],
    },
    {
        report_scope: ['dvm', 'ovm'],
        building_type_scope: ['apartment'],
        functionality: 'feature',
        type: 'int',
        unit: '',
        location: 'features',
        name: 'f_n_parking_spot',
        icon: '',
        fallback: '',
        values: null,
        sections: ['building'],
    },
    {
        report_scope: ['dvm', 'ovm'],
        building_type_scope: ['apartment'],
        functionality: 'feature',
        type: 'boolean',
        unit: '',
        location: 'features',
        name: 'f_cellar_attic',
        icon: '',
        fallback: '',
        values: null,
        sections: ['building'],
    },
    {
        report_scope: ['dvm', 'ovm'],
        building_type_scope: ['apartment'],
        functionality: 'feature',
        type: 'boolean',
        unit: '',
        location: 'features',
        name: 'f_garden_common',
        icon: '',
        fallback: '',
        values: null,
        sections: ['building'],
    },
    {
        report_scope: ['dvm', 'ovm'],
        building_type_scope: ['apartment'],
        functionality: 'feature',
        type: 'boolean',
        unit: '',
        location: 'features',
        name: 'f_garden_private',
        icon: '',
        fallback: '',
        values: null,
        sections: ['building'],
    },
    {
        report_scope: ['avm', 'dvm', 'ovm'],
        building_type_scope: ['house', 'apartment'],
        functionality: 'text',
        type: 'string',
        unit: '',
        location: 'section',
        name: 'building_structure',
        icon: '',
        fallback: '',
        values: null,
        sections: [],
    },
    {
        report_scope: ['core', 'avm', 'dvm', 'ovm'],
        building_type_scope: ['house', 'apartment'],
        functionality: 'feature',
        type: 'float',
        unit: 'm3',
        location: 'features',
        name: 'f_volume',
        icon: '',
        fallback: '',
        values: null,
        sections: ['building_structure', 'c_building'],
    },
    {
        report_scope: ['dvm', 'ovm'],
        building_type_scope: ['house', 'apartment'],
        functionality: 'feature',
        type: 'enum',
        unit: '',
        location: 'dvm_features',
        name: 'exterior_state',
        icon: '',
        fallback: '',
        values: ['maintenance_required', 'good', 'very_good'],
        sections: ['building_structure'],
    },
    {
        report_scope: ['dvm', 'ovm'],
        building_type_scope: ['house', 'apartment'],
        functionality: 'feature',
        type: 'enum',
        unit: '',
        location: 'dvm_features',
        name: 'facade_material',
        icon: '',
        fallback: '',
        values: [
            'cinder_blocks',
            'wood',
            'plaster',
            'natural_stone',
            'metal',
            'concrete',
            'brick',
        ],
        sections: ['building_structure'],
    },
    {
        report_scope: ['core', 'avm', 'dvm', 'ovm'],
        building_type_scope: ['house', 'apartment'],
        functionality: 'feature',
        type: 'float',
        unit: 'm2',
        location: 'features',
        name: 'f_roof_area',
        icon: '',
        fallback: '',
        values: null,
        sections: ['building_structure', 'roof'],
    },
    {
        report_scope: ['core', 'avm', 'dvm', 'ovm'],
        building_type_scope: ['house', 'apartment'],
        functionality: 'feature',
        type: 'float',
        unit: 'degree',
        location: 'features',
        name: 'f_mean_tilt',
        icon: '',
        fallback: '',
        values: null,
        sections: ['building_structure', 'roof'],
    },
    {
        report_scope: ['core', 'avm', 'dvm', 'ovm'],
        building_type_scope: ['house', 'apartment'],
        functionality: 'feature',
        type: 'float',
        unit: 'percent',
        location: 'features',
        name: 'f_percent_of_roof_flat',
        icon: '',
        fallback: '',
        values: null,
        sections: ['building_structure', 'roof'],
    },
    {
        report_scope: ['core'],
        building_type_scope: ['house', 'apartment'],
        functionality: 'feature',
        type: 'float',
        unit: 'm',
        location: 'features',
        name: 'f_min_height',
        icon: '',
        fallback: '',
        values: null,
        sections: ['building_structure', 'roof'],
    },
    {
        report_scope: ['core'],
        building_type_scope: ['house', 'apartment'],
        functionality: 'feature',
        type: 'float',
        unit: 'm',
        location: 'features',
        name: 'f_max_height',
        icon: '',
        fallback: '',
        values: null,
        sections: ['building_structure', 'roof'],
    },
    {
        report_scope: ['core'],
        building_type_scope: ['house', 'apartment'],
        functionality: 'feature',
        type: 'int',
        unit: '',
        location: 'features',
        name: 'f_n_parts',
        icon: '',
        fallback: '',
        values: null,
        sections: ['building_structure', 'c_building'],
    },
    {
        report_scope: ['core'],
        building_type_scope: ['house', 'apartment'],
        functionality: 'feature',
        type: 'int',
        unit: '',
        location: 'features',
        name: 'f_n_roof_panes',
        icon: '',
        fallback: '',
        values: null,
        sections: ['building_structure', 'roof'],
    },
    {
        report_scope: ['dvm', 'ovm'],
        building_type_scope: ['house', 'apartment'],
        functionality: 'feature',
        type: 'enum',
        unit: '',
        location: 'dvm_features',
        name: 'roof_material',
        icon: '',
        fallback: '',
        values: [
            'tiles',
            'bitumen_roofing',
            'metal',
            'slate_shingles',
            'black_concrete_tiles',
            'thatched',
            'asbestos',
        ],
        sections: ['building_structure'],
    },
    {
        report_scope: ['dvm', 'ovm'],
        building_type_scope: ['house', 'apartment'],
        functionality: 'feature',
        type: 'int',
        unit: '',
        location: 'dvm_features',
        name: 'n_roof_windows',
        icon: '',
        fallback: '',
        values: null,
        sections: ['building_structure_input'],
    },
    {
        report_scope: ['dvm', 'ovm'],
        building_type_scope: ['house', 'apartment'],
        functionality: 'feature',
        type: 'int',
        unit: '',
        location: 'dvm_features',
        name: 'n_roof_dormers',
        icon: '',
        fallback: '',
        values: null,
        sections: ['building_structure_input'],
    },
    {
        report_scope: ['dvm', 'ovm'],
        building_type_scope: ['house', 'apartment'],
        functionality: 'feature',
        type: 'float',
        unit: 'm2',
        location: 'dvm_features',
        name: 'solar_panel_area',
        icon: '',
        fallback: '',
        values: null,
        sections: ['building_structure'],
    },
    {
        report_scope: ['dvm', 'ovm'],
        building_type_scope: ['house', 'apartment'],
        functionality: 'text',
        type: 'string',
        unit: '',
        location: 'section',
        name: 'building_purpose_classification',
        icon: '',
        fallback: '',
        values: null,
        sections: [],
    },
    {
        report_scope: ['dvm', 'ovm'],
        building_type_scope: ['house', 'apartment'],
        functionality: 'feature',
        type: 'boolean',
        unit: '',
        location: 'dvm_features',
        name: 'f_building_listed',
        icon: '',
        fallback: '',
        values: null,
        sections: ['building_purpose_classification'],
    },
    {
        report_scope: ['dvm', 'ovm'],
        building_type_scope: ['house', 'apartment'],
        functionality: 'feature',
        type: 'boolean',
        unit: '',
        location: 'dvm_features',
        name: 'f_building_protected',
        icon: '',
        fallback: '',
        values: null,
        sections: ['building_purpose_classification'],
    },
    {
        report_scope: ['core', 'avm', 'dvm', 'ovm'],
        building_type_scope: ['house', 'apartment'],
        functionality: 'feature',
        type: 'enum',
        unit: '',
        location: '',
        name: 'clarify here',
        icon: '',
        fallback: '',
        values: null,
        sections: ['building_purpose_classification'],
    },
    {
        report_scope: ['core', 'avm', 'dvm', 'ovm'],
        building_type_scope: ['house', 'apartment'],
        functionality: 'feature',
        type: 'int',
        unit: '',
        location: 'features',
        name: 'f_x_matched_entities',
        icon: '',
        fallback: '',
        values: null,
        sections: ['economic_activity'],
    },
    {
        report_scope: ['core', 'avm', 'dvm', 'ovm'],
        building_type_scope: ['house', 'apartment'],
        functionality: 'feature',
        type: 'enum',
        unit: '',
        location: 'features',
        name: 'c_economic_activity_class',
        icon: '',
        fallback: '',
        values: [
            'home_liberal_profession_or_office',
            'home_other_professional_activity',
            'home',
        ],
        sections: ['economic_activity'],
    },
    {
        report_scope: ['core', 'avm', 'dvm', 'ovm'],
        building_type_scope: ['house', 'apartment'],
        functionality: 'feature',
        type: 'enum',
        unit: '',
        location: 'features',
        name: 'c_economic_activity_level',
        icon: '',
        fallback: '',
        values: [
            'no_economic_activity',
            'activity_unlikely',
            'activity_possible',
            'activity_likely',
            'activity_very_likely',
        ],
        sections: ['economic_activity'],
    },
    {
        report_scope: ['core', 'avm', 'dvm', 'ovm'],
        building_type_scope: ['house', 'apartment'],
        functionality: 'text',
        type: 'string',
        unit: '',
        location: 'section',
        name: 'parcels',
        icon: '',
        fallback: '',
        values: null,
        sections: [],
    },
    {
        report_scope: ['core', 'avm', 'dvm', 'ovm'],
        building_type_scope: ['house', 'apartment'],
        functionality: 'feature',
        type: 'float',
        unit: 'm2',
        location: 'features',
        name: 'f_garden_area',
        icon: '',
        fallback: '',
        values: null,
        sections: ['parcels', 'c_parcels'],
    },
    {
        report_scope: ['dvm', 'ovm'],
        building_type_scope: ['house', 'apartment'],
        functionality: 'feature',
        type: 'enum',
        unit: '',
        location: 'dvm_features',
        name: 'garden_orientation',
        icon: '',
        fallback: '',
        values: ['n', 'ne', 'e', 'se', 's', 'sw', 'w', 'nw'],
        sections: ['parcels'],
    },
    {
        report_scope: ['dvm', 'ovm'],
        building_type_scope: ['house', 'apartment'],
        functionality: 'feature',
        type: 'enum',
        unit: '',
        location: 'dvm_features',
        name: 'garden_arrangement',
        icon: '',
        fallback: '',
        values: ['limited', 'good', 'very_good'],
        sections: ['parcels'],
    },
    {
        report_scope: ['core', 'avm', 'dvm', 'ovm'],
        building_type_scope: ['house'],
        functionality: 'feature',
        type: 'bool',
        unit: '',
        location: 'features',
        name: 'f_swimming_pool',
        icon: '',
        fallback: '',
        values: null,
        sections: ['parcels', 'c_parcels'],
    },
    {
        report_scope: ['core', 'avm', 'dvm', 'ovm'],
        building_type_scope: ['house'],
        functionality: 'feature',
        type: 'float',
        unit: 'm2',
        location: 'features',
        name: 'f_swimming_pool_area',
        icon: '',
        fallback: '',
        values: null,
        sections: ['parcels', 'c_parcels'],
    },
    {
        report_scope: ['avm', 'dvm', 'ovm'],
        building_type_scope: ['house', 'apartment'],
        functionality: 'feature',
        type: 'fixed',
        unit: '',
        location: 'fixed',
        name: 'parcels_and_building_shapes',
        icon: '',
        fallback: '',
        values: ['details_previous_page'],
        sections: ['parcels'],
    },
    {
        report_scope: ['dvm', 'ovm'],
        building_type_scope: ['house', 'apartment'],
        functionality: 'text',
        type: 'string',
        unit: '',
        location: 'section',
        name: 'surroundings',
        icon: '',
        fallback: '',
        values: null,
        sections: [],
    },
    {
        report_scope: ['core', 'avm', 'dvm', 'ovm'],
        building_type_scope: ['house', 'apartment'],
        functionality: 'feature',
        type: 'float',
        unit: 'm',
        location: 'features',
        name: 'f_distance_to_street',
        icon: '',
        fallback: '',
        values: null,
        sections: ['surroundings'],
    },
    {
        report_scope: ['dvm', 'ovm'],
        building_type_scope: ['house', 'apartment'],
        functionality: 'feature',
        type: 'enum',
        unit: '',
        location: 'dvm_features',
        name: 'direct_traffic_level',
        icon: '',
        fallback: '',
        values: ['low', 'medium', 'high'],
        sections: ['surroundings'],
    },
    {
        report_scope: ['dvm', 'ovm'],
        building_type_scope: ['house', 'apartment'],
        functionality: 'feature',
        type: 'enum',
        unit: '',
        location: 'dvm_features',
        name: 'indirect_traffic_level',
        icon: '',
        fallback: '',
        values: ['low', 'medium', 'high'],
        sections: ['surroundings'],
    },
    {
        report_scope: ['core', 'avm', 'dvm', 'ovm'],
        building_type_scope: ['house', 'apartment'],
        functionality: 'feature',
        type: 'float',
        unit: 'm',
        location: 'features',
        name: 'f_dist_building_flood',
        icon: '',
        fallback: '',
        values: null,
        sections: ['surroundings', 'flood'],
    },
    {
        report_scope: ['core', 'avm', 'dvm', 'ovm'],
        building_type_scope: ['house', 'apartment'],
        functionality: 'feature',
        type: 'float',
        unit: 'm',
        location: 'features',
        name: 'f_dist_building_flood_insurance_exclusion',
        icon: '',
        fallback: '',
        values: null,
        sections: ['surroundings', 'flood'],
    },
    {
        report_scope: ['core', 'avm', 'dvm', 'ovm'],
        building_type_scope: ['house', 'apartment'],
        functionality: 'feature',
        type: 'enum',
        unit: '',
        location: 'features',
        name: 'f_flood_risk',
        icon: '',
        fallback: '',
        values: ['high', 'medium', 'low', 'unknown', 'none'],
        sections: ['surroundings', 'flood'],
    },
    {
        report_scope: ['core', 'avm', 'dvm', 'ovm'],
        building_type_scope: ['house', 'apartment'],
        functionality: 'feature',
        type: 'enum',
        unit: '',
        location: 'features',
        name: 'f_flood_risk_insurance_exclusion',
        icon: '',
        fallback: '',
        values: ['high', 'none'],
        sections: ['surroundings', 'flood'],
    },
    {
        report_scope: ['core', 'avm', 'dvm', 'ovm'],
        building_type_scope: ['house', 'apartment'],
        functionality: 'feature',
        type: 'enum',
        unit: '',
        location: 'features',
        name: 'f_flood_risk_composite',
        icon: '',
        fallback: '',
        values: ['high', 'medium', 'low', 'none'],
        sections: ['surroundings', 'flood'],
    },
    {
        report_scope: ['dvm', 'ovm'],
        building_type_scope: ['house', 'apartment'],
        functionality: 'feature',
        type: 'enum',
        unit: '',
        location: 'dvm_features',
        name: 'surroundings',
        icon: '',
        fallback: '',
        values: ['city', 'suburbs', 'village', 'isolated'],
        sections: ['surroundings'],
    },
    {
        report_scope: ['dvm', 'ovm'],
        building_type_scope: ['house', 'apartment'],
        functionality: 'feature',
        type: 'multi_enum_open',
        unit: '',
        location: 'dvm_features',
        name: 'other_positive',
        icon: '',
        fallback: '',
        values: [
            'classified_facade',
            'green_neighbourhood',
            'sea_view',
            'no_direct_traffic',
            'exceptional_view',
        ],
        sections: ['surroundings'],
    },
    {
        report_scope: ['dvm', 'ovm'],
        building_type_scope: ['house', 'apartment'],
        functionality: 'feature',
        type: 'multi_enum_open',
        unit: '',
        location: 'dvm_features',
        name: 'other_negative',
        icon: '',
        fallback: '',
        values: ['tram', 'train', 'metro', 'traffic', 'busy_area'],
        sections: ['surroundings'],
    },
    {
        report_scope: ['dvm', 'ovm'],
        building_type_scope: ['house', 'apartment'],
        functionality: 'feature',
        type: 'string',
        unit: '',
        location: 'dvm_features',
        name: 'remarks',
        icon: '',
        fallback: '',
        values: null,
        sections: [],
    },
    {
        report_scope: ['avm', 'dvm', 'ovm'],
        building_type_scope: ['house', 'apartment'],
        functionality: 'text',
        type: 'string',
        unit: '',
        location: 'section',
        name: 'valuations',
        icon: '',
        fallback: '',
        values: null,
        sections: [],
    },
    {
        report_scope: ['avm', 'dvm', 'ovm'],
        building_type_scope: ['house', 'apartment'],
        functionality: 'feature',
        type: 'float',
        unit: 'euro',
        location: 'valuation',
        name: 'market_value',
        icon: '',
        fallback: '',
        values: null,
        sections: ['valuations'],
    },
    {
        report_scope: ['avm', 'dvm', 'ovm'],
        building_type_scope: ['house', 'apartment'],
        functionality: 'feature',
        type: 'float',
        unit: 'euro',
        location: 'valuation',
        name: 'forced_sale_value',
        icon: '',
        fallback: '',
        values: null,
        sections: ['valuations'],
    },
    {
        report_scope: ['avm', 'dvm', 'ovm'],
        building_type_scope: ['house', 'apartment'],
        functionality: 'feature',
        type: 'float',
        unit: 'euro/month',
        location: 'valuation',
        name: 'rental_value',
        icon: '',
        fallback: '',
        values: null,
        sections: ['valuations'],
    },
    {
        report_scope: ['core', 'avm', 'dvm', 'ovm'],
        building_type_scope: ['house', 'apartment'],
        functionality: 'feature',
        type: 'float',
        unit: 'euro',
        location: 'valuation',
        name: 'reconstruction_value',
        icon: '',
        fallback: '',
        values: null,
        sections: ['valuations', 'reconstruction_value'],
    },
    {
        report_scope: ['core', 'avm', 'dvm', 'ovm'],
        building_type_scope: ['house', 'apartment'],
        functionality: 'feature',
        type: 'float',
        unit: '',
        location: 'valuation',
        name: 'index_value',
        icon: '',
        fallback: '',
        values: null,
        sections: ['valuations', 'reconstruction_value'],
    },
    {
        report_scope: ['core', 'avm', 'dvm', 'ovm'],
        building_type_scope: ['house', 'apartment'],
        functionality: 'feature',
        type: 'enum',
        unit: '',
        location: 'valuation',
        name: 'index_type',
        icon: '',
        fallback: '',
        values: ['abex'],
        sections: ['valuations', 'reconstruction_value'],
    },
    {
        report_scope: ['core', 'avm', 'dvm', 'ovm'],
        building_type_scope: ['house', 'apartment'],
        functionality: 'feature',
        type: 'string',
        unit: '',
        location: 'features',
        name: 'level',
        icon: '',
        fallback: '',
        values: null,
        sections: ['reconstruction_value'],
    },
    {
        report_scope: ['avm', 'dvm', 'ovm'],
        building_type_scope: ['house', 'apartment'],
        functionality: 'text',
        type: 'string',
        unit: '',
        location: 'section',
        name: 'reference_properties_location',
        icon: '',
        fallback: '',
        values: null,
        sections: [],
    },
    {
        report_scope: ['avm', 'dvm', 'ovm'],
        building_type_scope: ['house', 'apartment'],
        functionality: 'section',
        type: 'string',
        unit: '',
        location: 'section',
        name: 'reference_properties_characteristics',
        icon: '',
        fallback: '',
        values: null,
        sections: [],
    },
    {
        report_scope: ['core', 'avm', 'dvm', 'ovm'],
        building_type_scope: ['house', 'apartment'],
        functionality: 'section',
        type: 'string',
        unit: '',
        location: 'section',
        name: 'flood',
        icon: 'ic-flood',
        fallback: '',
        values: null,
        sections: [],
    },
    {
        report_scope: ['core', 'avm', 'dvm', 'ovm'],
        building_type_scope: ['house', 'apartment'],
        functionality: 'feature',
        type: 'float',
        unit: 'm',
        location: 'features',
        name: 'f_ground_height_above_sea',
        icon: '',
        fallback: '',
        values: null,
        sections: ['flood'],
    },
    {
        report_scope: ['core', 'avm', 'dvm', 'ovm'],
        building_type_scope: ['house', 'apartment'],
        functionality: 'section',
        type: 'string',
        unit: '',
        location: 'section',
        name: 'faq',
        icon: '',
        fallback: '',
        values: null,
        sections: [],
    },
    {
        report_scope: ['avm', 'dvm', 'ovm'],
        building_type_scope: ['house', 'apartment'],
        functionality: 'section',
        type: 'string',
        unit: '',
        location: 'section',
        name: 'questions_feedback',
        icon: '',
        fallback: '',
        values: null,
        sections: [],
    },
    {
        report_scope: ['core', 'avm', 'dvm', 'ovm'],
        building_type_scope: ['house', 'apartment'],
        functionality: 'section',
        type: 'string',
        unit: '',
        location: 'section',
        name: 'contact_info',
        icon: '',
        fallback: '',
        values: null,
        sections: [],
    },
    {
        report_scope: ['avm', 'dvm', 'ovm'],
        building_type_scope: ['house', 'apartment'],
        functionality: 'section',
        type: 'string',
        unit: '',
        location: 'section',
        name: 'disclaimer',
        icon: '',
        fallback: '',
        values: null,
        sections: [],
    },
]
export const translations = {
    'be-FR': {
        unit: {
            m: 'm',
            m2: 'm\u00b2',
            m3: 'm\u00b3',
            'kwh/m2year': 'kWh/m\u00b2an',
        },
        address_text: {
            full_address: 'Adresse',
        },
        valuation: {
            date: 'Date de la valorisation',
            type: 'Type de valorisation',
            valuer: 'Expert',
            market_value: 'Valeur de march\u00e9',
            forced_sale_value: 'Valeur de vente forc\u00e9e',
            rental_value: 'Valeur locative mensuelle',
            reconstruction_value: 'Valeur de reconstruction',
            index_value: 'Index',
            index_type: "Type d'index",
            explanation_1:
                "La valorisation de ce bien repose en partie sur un mod\u00e8le statistique fond\u00e9 sur des donn\u00e9es. Son r\u00e9sultat a \u00e9t\u00e9 revu et affin\u00e9 par un expert immobilier. Il s'agit d'un processus en deux \u00e9tapes :",
            explanation_2: '\u00c9tape 1 : Estimation par le mod\u00e8le de valorisation',
            explanation_3:
                'Une premi\u00e8re estimation de la valeur de march\u00e9 de la propri\u00e9t\u00e9 est calcul\u00e9e par le mod\u00e8le sur la base de toutes les informations fournies par le client et de certains param\u00e8tres automatiques (cf. page 2). Cette valorsiation repr\u00e9sente la valeurde march\u00e9 moyenne pour une propri\u00e9t\u00e9 ayant cette localisation et ces caract\u00e9ristiques.',
            explanation_4:
                '\u00c9tape 2 : Evaluation et mise au point par un expert immobilier',
            explanation_5:
                "L'expert immobilier \u00e9value la valorisation du mod\u00e8le utilis\u00e9e en \u00c9tape 1 et prend ensuite en compte les autres param\u00e8tres entr\u00e9s automatiquement, ainsi que les param\u00e8tres entr\u00e9s par l'expert. Il en r\u00e9sulte une valuer de march\u00e9 du bien (ligne rouge dans le graphique). En fonction des corrections appliqu\u00e9es par l'expert immobilier, la valeur de march\u00e9 peut \u00eatre inf\u00e9rieure ou sup\u00e9rieure \u00e0 l'estimation initiale du mod\u00e8le de valorisation.\n\nLe graphique ci-dessous illustre comment la valeur de march\u00e9 de cette propri\u00e9t\u00e9 se compare \u00e0 d'autres propri\u00e9t\u00e9s dans le voisinage. Il permet toujours de contextualiser la valorisation r\u00e9alis\u00e9e par l'expert.",
            explanation_6:
                "La valeur de march\u00e9 indiqu\u00e9e dans ce rapport est bas\u00e9e sur une valorisation \u00e0 distance r\u00e9alis\u00e9e par un expert. Elle vise \u00e0 donner la meilleure vue possible sur la valeur actuelle du bien. Cependant, elle reste une estimation o\u00f9 les \u00e9l\u00e9ments suivants doivent \u00eatre pris en compte :\n- Valorisation \u00e0 distance: l'expert a \u00e9valu\u00e9 le bien \u00e0 distance et n'a pas r\u00e9ellement visit\u00e9 le bien.\n- Pr\u00e9cision du mod\u00e8le: la valorisation repose, en partie, sur un mod\u00e8le statistique avec une certaine marge d'erreur.\n- Degr\u00e9 de subjectivit\u00e9: m\u00eame la meilleure valorisation par un expert est quelque peu biais\u00e9e par une forme de subjectivit\u00e9.",
        },
        value: {
            avm: 'Valorisation automatique',
            dvm: 'Valorisation \u00e0 distance',
            ovm: 'Valorisation sur place',
            new_loan: 'Demande d\u2019un pr\u00eat immobilier',
            revaluation: 'Revalorisation',
            house: 'Maison',
            apartment: 'Appartement',
            annex: 'Annexe',
            attached: '2 fa\u00e7ades',
            semi: '3 fa\u00e7ades',
            detached: '4 fa\u00e7ades',
            yes: 'Oui',
            no: 'Non',
            traditional: 'Traditionel',
            modern: 'Moderne',
            contemporary: 'Contemporain',
            mediterranean: 'M\u00e9diterran\u00e9en',
            other: 'Autres',
            maintenance_required: "Besoin d'entretien",
            good: 'Bon',
            very_good: 'Tr\u00e8s bon',
            cinder_blocks: 'Parpaings',
            wood: 'Bois',
            plaster: 'Cr\u00e9pi',
            natural_stone: 'Pierres naturelles',
            metal: 'M\u00e9tal',
            concrete: 'B\u00e9ton',
            brick: 'Briques',
            tiles: 'Tuiles',
            bitumen_roofing: 'Bit\u00fbme',
            slate_shingles: 'Bardeaux en ardoise',
            black_concrete_tiles: 'Tuiles noires en b\u00e9ton',
            thatched: 'Chaume',
            asbestos: 'Amiante',
            home_liberal_profession_or_office:
                'Maison, profession lib\u00e9rale ou bureau',
            home_other_professional_activity: 'Autre activit\u00e9 professionelle',
            home: 'Maison',
            no_economic_activity: "Pas d'activit\u00e9 \u00e9conomique",
            activity_unlikely: 'Activit\u00e9 improbable',
            activity_possible: 'Activit\u00e9 possible',
            activity_likely: 'Activit\u00e9 probable',
            activity_very_likely: 'Activit\u00e9 tr\u00e8s probable',
            n: 'N',
            ne: 'NE',
            e: 'E',
            se: 'SE',
            s: 'S',
            sw: 'SO',
            w: 'O',
            nw: 'NO',
            limited: 'Limit\u00e9',
            details_previous_page: '(d\u00e9tails sur la page pr\u00e9c\u00e9dente)',
            low: 'Faible',
            medium: 'Mod\u00e9r\u00e9',
            high: 'Elev\u00e9',
            unknown: 'Inconnu',
            city: 'Ville',
            suburbs: 'Banlieue',
            village: 'Village',
            isolated: 'Isol\u00e9',
            none: 'Aucun',
            classified_facade: 'Fa\u00e7ade class\u00e9e',
            green_neighbourhood: 'Environnement vert',
            sea_view: 'Vue sur mer',
            no_direct_traffic: 'Pas de trafic direct',
            exceptional_view: 'Vue exceptionelle',
            tram: 'Tram',
            train: 'Train',
            metro: 'M\u00e9tro',
            traffic: 'Trafic',
            busy_area: 'Zone fr\u00e9quent\u00e9e',
            abex: 'ABEX',
        },
        request: {
            valuation_request_ref: 'R\u00e9f\u00e9rence de la valorisation',
            bank: 'Banque',
            customer: 'Client',
            purpose: 'Objectif de la valorisation',
        },
        footer: {
            text: 'Pour de plus amples informations, visitez {url.rockestate_main}',
        },
        url: {
            rockestate_main: 'https://www.rockestate.io',
            dashboard: 'https://ing.rock.estate/valuation/default/dashboard',
            rockestate_info: 'https://www.rockestate.io/',
            valuation_app: 'https://ing.rock.estate/valuation/be-FR/',
        },
        export: {
            by: 'Export\u00e9 par',
            date: 'Date',
            reference: 'R\u00e9f\u00e9rence',
        },
        section: {
            reconstruction_value: 'Reconstruction',
            address_location: 'Adresse et localisation',
            secondary_buildings: 'B\u00e2timents secondaires',
            economic_activity: 'Activit\u00e9 \u00e9conomique',
            roof: 'Toit',
            warnings: 'Avertissements',
            general_info: 'Information g\u00e9n\u00e9rales',
            parcels_and_buildings: 'Parcelles et b\u00e2timents',
            c_parcels: 'Terrain',
            c_building: 'B\u00e2timent principal',
            building: 'B\u00e2timent',
            building_structure: 'Structure du b\u00e2timent',
            building_purpose_classification:
                'Utilisation & classification du b\u00e2timent',
            parcels: 'Parcelle(s)',
            surroundings: 'Environnement proche',
            valuations: 'Valorisations',
            reference_properties_location: 'Biens de r\u00e9f\u00e9rences : emplacement',
            reference_properties_characteristics:
                'Biens de r\u00e9f\u00e9rences : caract\u00e9ristiques',
            flood: 'Zones inondables',
            faq: 'Foire aux questions',
            questions_feedback: 'Questions et commentaires',
            contact_info: 'Coordonn\u00e9es de contact',
            disclaimer: 'Mentions l\u00e9gales',
        },
        front: {
            title: 'Rapport de valorisation de la propri\u00e9t\u00e9',
            core_title: 'Rapport de propri\u00e9t\u00e9',
        },
        disclaimer: {
            dvm:
                "Ce rapport de valorisation est le r\u00e9sultat d'une valorisation \u00e0 distance du bien par un expert immobilier. L'expert n'a pas visit\u00e9 le bien mais s'est appuy\u00e9 sur diverses sources de donn\u00e9es et un mod\u00e8le statistique pour effectuer une valorisation ind\u00e9pendante du bien. Pour plus d'informations concernant cette approche de valorisation, veuillez vous r\u00e9f\u00e9rer \u00e0 la derni\u00e8re page de ce rapport.",
            ovm:
                "Ce rapport d'\u00e9valuation est le r\u00e9sultat d'une \u00e9valuation sur place du bien par un expert immobilier. La valorisation ind\u00e9pendante du bien, est bas\u00e9e sur la situation observ\u00e9e sur place par l'expert, et est soustenue par un mod\u00e8le statistique. Pour plus d'informations concernant cette approche d'\u00e9valuation, veuillez vous r\u00e9f\u00e9rer \u00e0 la derni\u00e8re page de ce rapport.",
            part_1:
                "Cette valorisation a \u00e9t\u00e9 r\u00e9alis\u00e9e dans le but exprim\u00e9 ici, aucune responsabilit\u00e9 n'est accept\u00e9e dans le cas o\u00f9 ce rapport serait utilis\u00e9 dans un autre but ou par une autre partie que celle \u00e0 laquelle il est adress\u00e9. Si une autre partie souhaite utiliser ou s'appuyer sur ce rapport \u00e0 quelque fin que ce soit, elle doit d'abord obtenir le consentement \u00e9crit de Rockestate.\n",
            part_2:
                "La publication de ce rapport, en tout ou en partie, est interdite sans l'accord \u00e9crit de Rockestate. Les droits d'auteur de ce rapport restent \u00e0 tout moment la propri\u00e9t\u00e9 de Rockestate. Ce rapport ne peut \u00eatre c\u00e9d\u00e9 \u00e0 un tiers sans le consentement expr\u00e8s du propri\u00e9taire du droit d'auteur.",
            part_3:
                "En faisant ce rapport de valorisation, nous n'exprimons aucune opinion et n'acceptons aucune responsabilit\u00e9 quant \u00e0 la capacit\u00e9 et \u00e0 la volont\u00e9 du d\u00e9biteur hypoth\u00e9caire de respecter ses engagements dans le cadre du pr\u00eat propos\u00e9.",
        },
        features: {
            f_building_type: 'Type de bien',
            f_number_of_facades: 'Nombre de fa\u00e7ades',
            f_parcel_area: 'Superficie totale de la parcelle',
            f_building_area: 'Surface au sol du b\u00e2timent',
            f_x_annexes: 'B\u00e2timents secondaires',
            f_annexes_area: 'Surface totale des b\u00e2timents secondaires',
            f_area_largest_annex: 'Plus grand b\u00e2timent secondaire',
            parcel_ids: 'R\u00e9f\u00e9rences cadastrales',
            f_lng: 'Longitude',
            f_lat: 'Latitude',
            f_number_of_addresses: "Nombre d'adresses",
            f_living_area: 'Superficie habitable',
            f_construction_year: 'Ann\u00e9e de construction',
            f_epc: '',
            f_epc_label: '',
            f_epc_numeric: 'PEB',
            f_top_floor: "Dernier \u00e9tage de l'immeuble",
            f_floors: "\u00c9tage(s) de l'appartement",
            f_balcony_present: 'Terrasse',
            f_n_closed_garage: 'Nombre de garages ferm\u00e9s',
            f_n_parking_spot: "Nombre d'emplacement de parkings",
            f_cellar_attic: 'Cave et/ou grenier',
            f_garden_common: 'Acc\u00e8s \u00e0 un jardin commun',
            f_garden_private: 'Acc\u00e8s \u00e0 un jardin priv\u00e9',
            f_volume: 'Volume du b\u00e2timent principal',
            f_roof_area: 'Surface du toit',
            f_mean_tilt: 'Inclinaison moyenne du toit',
            f_percent_of_roof_flat: 'Proportion de toit plat',
            f_min_height: 'Hauteur minimale du toit',
            f_max_height: 'Hauteur maximale du toit',
            f_n_parts: 'Parties du b\u00e2timent principal',
            f_n_roof_panes: 'Pans de toit',
            f_x_matched_entities: 'Entit\u00e9s connues \u00e0 cette adresse',
            c_economic_activity_class: "Classe d'activit\u00e9 \u00e9conomique",
            c_economic_activity_level: "Niveau d'activit\u00e9 \u00e9conomique",
            f_garden_area: 'Surface du jardin',
            f_swimming_pool: 'Piscine ext\u00e9rieure',
            f_swimming_pool_area: 'Taille de la piscine ext\u00e9rieure',
            f_distance_to_street: 'Distance par rapport au milieu de la rue',
            f_dist_building_flood: 'Zone inondable la plus proche',
            f_dist_building_flood_insurance_exclusion:
                "Zone inondable la plus proche\n(zones d'al\u00e9a \u00e9lev\u00e9)",
            f_flood_risk: "Risque d'inondation",
            f_flood_risk_insurance_exclusion:
                "Risque d'inondation\n(zones d'al\u00e9a \u00e9lev\u00e9)",
            f_flood_risk_composite: "Risque d'inondation composite",
            level: "Niveau d'information",
            f_ground_height_above_sea: 'Altitude (au-dessus du niveau de la mer)',
        },
        info: {
            data_from_customer: 'donn\u00e9es saisies par le client',
        },
        dvm_features: {
            style_of_house: 'Style de construction',
            exterior_state: '\u00c9tat ext\u00e9rieur de la propri\u00e9t\u00e9',
            facade_material: 'Mat\u00e9riaux des fa\u00e7ades',
            roof_material: 'Mat\u00e9riaux de toiture',
            n_roof_windows: 'Nombre de fen\u00eatres de toit',
            n_roof_dormers: 'Nombre de lucarnes',
            solar_panel_area: 'Surface des panneaux solaires',
            f_building_listed: 'Class\u00e9 \u00e0 des fins historiques',
            f_building_protected: 'Classification',
            garden_orientation: 'Orientation du jardin',
            garden_arrangement: 'Am\u00e9nagement (possible) du jardin',
            direct_traffic_level: 'Niveau de trafic direct dans la voirie',
            indirect_traffic_level: 'Proximit\u00e9 du trafic indirecte sur la voirie',
            surroundings: 'Description des environs',
            other_positive: 'Autres \u00e9l\u00e9ments ayant un impact positif',
            other_negative: 'Autres \u00e9l\u00e9ments ayant un impact n\u00e9gatif',
            remarks: 'Remarques',
        },
        '': {
            'clarify here': 'Activit\u00e9 \u00e9conomique',
        },
        fixed: {
            parcels_and_building_shapes: 'Formes de parcelles et de b\u00e2timents',
        },
        reference_properties_location: {
            explanation_1:
                "La valorisation de cette propri\u00e9t\u00e9 repose sur 200 biens de r\u00e9f\u00e9rence qui ont \u00e9t\u00e9 s\u00e9lectionn\u00e9es dans le voisinage de la propri\u00e9t\u00e9. Chaque bien de r\u00e9f\u00e9rence pr\u00e9sente un certain nombre de similitudes avec la propri\u00e9t\u00e9 \u00e9valu\u00e9e en termes d'emplacement, de taille et de plusieurs autres caract\u00e9ristiques. Le mod\u00e8le statistique sous-jacent de cette valorisation est bas\u00e9 sur une r\u00e9gression g\u00e9ographiquement pond\u00e9r\u00e9e (GWR) o\u00f9 les biens de r\u00e9f\u00e9rence situ\u00e9s \u00e0 proximit\u00e9 imm\u00e9diate de la propri\u00e9t\u00e9 re\u00e7oivent une pond\u00e9ration plus importante que les biens de r\u00e9f\u00e9rence situ\u00e9s dans un rayon plus \u00e9loign\u00e9.",
            explanation_2:
                "La carte ci-dessous donne un aper\u00e7u de l'emplacement des biens de r\u00e9f\u00e9rence et de leur distance par rapport \u00e0 la propri\u00e9t\u00e9 \u00e9valu\u00e9e. La l\u00e9gende des couleur sur la carte donne une indication de la valeur de march\u00e9 moyenne des biens immobiliers dans votre r\u00e9gion.",
        },
        reference_properties_characteristics: {
            explanation_1:
                "Une propri\u00e9t\u00e9 poss\u00e8de diverses caract\u00e9ristiques objectives qui contribuent \u00e0 son prix, telles que l'emplacement, la taille et l'efficacit\u00e9 \u00e9nerg\u00e9tique. Les graphiques ci-dessous comparent certaines caract\u00e9ristiques de cette propri\u00e9t\u00e9 avec d\u2019autres biens plus ou moins proches.",
            property_characteristics: 'Caract\u00e9ristiques de la propri\u00e9t\u00e9',
            properties_in_the_area: 'Propri\u00e9t\u00e9s dans votre r\u00e9gion',
        },
        flood: {
            explanation_1:
                "Aper\u00e7u des zones d'inondation officielles \u00e0 proximit\u00e9 de la propri\u00e9t\u00e9. Plusieurs cat\u00e9gories de risque d'inondation sont calcul\u00e9es en fonction de diff\u00e9rents sc\u00e9narios de risque d'inondation, allant de tr\u00e8s faible \u00e0 \u00e9lev\u00e9.",
        },
        faq: {
            q_incorrect_valuation:
                "L'adresse du bien \u00e9valu\u00e9 est incorrecte, que dois-je faire?",
            a_incorrect_valuation:
                "Il semble que quelque chose ait d\u00fb mal se passer lors de la s\u00e9lection de l'adresse, du b\u00e2timent ou des parcelles li\u00e9es \u00e0 la propri\u00e9t\u00e9. Vous pouvez revoir les informations sur l'adresse donn\u00e9e via le lien suivant : {url.dashboard}\u2028.",
            q_incorrect_characteristics:
                "L'adresse du bien \u00e9valu\u00e9 est correcte, mais certaines des autres caract\u00e9ristiques de ce rapport sont incorrectes, que dois-je faire?",
            a_incorrect_characteristics:
                'Si vous pensez que certaines informations dans ce rapport peuvent diff\u00e9rer substantiellement de la situation r\u00e9elle, ce qui pourrait avoir un impact important sur la valorisation de la propri\u00e9t\u00e9, suivez ce lien {url.dashboard} pour revoir et clarifier les informations incorrectes/manquantes.\u2028',
            q_transaction_vs_market_value:
                "Pourquoi le prix de la transaction que j'ai pay\u00e9 est diff\u00e9rent de la valeur de march\u00e9 d\u00e9termin\u00e9e dans ce rapport?",
            a_transaction_vs_market_value_dvm:
                "Ce rapport de valorisation est le r\u00e9sultat de l'\u00e9valuation \u00e0 distance du bien en question par un expert immobilier, soutenue par un mod\u00e8le statistique. Le rapport devrait donner une indication pr\u00e9cise de la valeur de march\u00e9 attendue du bien, \u00e0 base des informations disponibles. \u00c9tant donn\u00e9 que l'expert n'a pas r\u00e9ellement visit\u00e9 la propri\u00e9t\u00e9, il peut arriver que l'impact de certaines caract\u00e9ristiques sp\u00e9cifiques \u00e0 ce bien ne soit pas enti\u00e8rement refl\u00e9t\u00e9 dans la valuer de march\u00e9 estim\u00e9e. Veuillez consulter notre page d'information via le lien suivant : {url.rockestate_info}.",
            a_transaction_vs_market_value_ovm:
                "Ce rapport d'\u00e9valuation est le r\u00e9sultat de l'\u00e9valuation \u00e0 distance d'un expert immobilier, soutenue par un mod\u00e8le statistique bas\u00e9 sur des donn\u00e9es. Le rapport doit donner une indication pr\u00e9cise de la valeur attendue du bien, sur la base des informations disponibles. \u00c9tant donn\u00e9 que l'\u00e9valuateur n'a pas r\u00e9ellement visit\u00e9 le bien, il peut arriver que l'impact de certaines caract\u00e9ristiques sp\u00e9cifiques \u00e0 ce bien ne soit pas enti\u00e8rement refl\u00e9t\u00e9 dans le prix de transaction estim\u00e9. Veuillez consulter notre page d'information via le lien suivant : {url.rockestate_info}.",
            q_loan_accepted:
                "Maintenant que j'ai re\u00e7u mon rapport de valorisation, cela signifie-t-il que ma demande de pr\u00eat a \u00e9t\u00e9 accept\u00e9e?",
            a_loan_accepted:
                "Nous avons partag\u00e9 ce rapport de valorisation avec vous et votre banque. Ce rapport de valorisation sera utilis\u00e9 par la banque comme l'un des \u00e9l\u00e9ments de son processus d'approbation du risque de cr\u00e9dit. La d\u00e9cision finale reste la leur. Rockestate n'intervient en aucune fa\u00e7on dans cette d\u00e9cision.",
        },
        questions_feedback: {
            explanation_1:
                "Vous avez une question ou un commentaire sp\u00e9cifique concernant ce rapport? Ou vous souhaitez simplement obtenir plus d'informations sur la mani\u00e8re dont Rockestate proc\u00e8de \u00e0 ces valorisations?",
            explanation_2:
                'Veuillez visiter notre plateforme de valorisation : {url.valuation_app}.',
        },
    },
    'be-NL': {
        unit: {
            m: 'm',
            m2: 'm\u00b2',
            m3: 'm\u00b3',
            'kwh/m2year': 'kWh/m\u00b2jr',
        },
        address_text: {
            full_address: 'Adres',
        },
        valuation: {
            date: 'Waarderingsdatum',
            type: 'Soort waardering',
            valuer: 'Schatter',
            market_value: 'Marktwaarde',
            forced_sale_value: 'Gedwongen verkoopwaarde',
            rental_value: 'Maandelijkse huurprijs',
            reconstruction_value: 'Heropbouwwaarde',
            index_value: 'Index',
            index_type: 'Soort index',
            explanation_1:
                'De waardering van dit gebouw berust gedeeltelijk op een data-gedreven statistisch model. Het resultaat werd door een vastgoedschatter gecontroleerd en bijgestuurd waar nodig. Het betreft een proces in twee stappen:',
            explanation_2: 'Stap 1: Schatting door waarderingsmodel',
            explanation_3:
                'Een eerste schatting van de marktwaarde van het pand wordt door het model berekend op basis van alle input van de klant alsook enkele bijkomende inputparameters (cf. blz. 2). Deze schatting geeft de gemiddelde marktwaarde voor een pand met deze locatie en karakteristieken.',
            explanation_4: 'Stap 2: Evaluatie en verfijning door vastgoedschatter',
            explanation_5:
                'De vastgoedschatter verifieert het model in stap 1 en houdt vervolgens rekening met de andere inputparameters. Dit resulteert in de marktwaarde van het pand (rode lijn in grafiek). Afhankelijk van de correcties die door de vastgoedschatter worden toegepast, kan de marktwaarde lager of hoger uitvallen dan de oorspronkelijke waardering van het model.\nDe onderstaande grafiek illustreert hoe de marktwaarde van dit pand zich vergelijkt met andere panden in de omgeving. Het kan helpen om deze waardering te contextualiseren.',
            explanation_6:
                'De marktwaarde in dit rapport is gebaseerd op een waardering uitgevoerd door een vastgoedschatter op afstand. De waardering tracht een zo goed mogelijk zicht te geven op de huidige waarde van het pand. Het blijft echter een schatting waarbij de volgende elementen in aanmerking moeten worden genomen:\n- Waardering op afstand: de schatter heeft het pand van op afstand ge\u00ebvalueerd en heeft dit niet effectief bezocht.\n- Nauwkeurigheid van het model: de waardering berust gedeeltelijk op een statistisch model met een bepaalde foutenmarge.\n- Subjectiviteit: zelfs de best mogelijke waardering uitgevoerd door een schatter bevat een zeker niveau van subjectiviteit.',
        },
        value: {
            avm: 'Automatische waardering',
            dvm: 'Waardering op afstand',
            ovm: 'Waardering ter plaatse',
            new_loan: 'Nieuwe kredietaanvraag',
            revaluation: 'Herwaardering',
            house: 'Huis',
            apartment: 'Appartement',
            annex: 'Bijgebouw',
            attached: 'Gesloten bebouwing',
            semi: 'Half-open bebouwing',
            detached: 'Open bebouwing',
            yes: 'Ja',
            no: 'Nee',
            traditional: 'Traditioneel',
            modern: 'Modern',
            contemporary: 'Hedendaags',
            mediterranean: 'Mediterraans',
            other: 'Andere',
            maintenance_required: 'Onderhoud nodig',
            good: 'Goed',
            very_good: 'Zeer goed',
            cinder_blocks: 'Sintelblokken',
            wood: 'Hout',
            plaster: 'Gips',
            natural_stone: 'Natuursteen',
            metal: 'Metaal',
            concrete: 'Beton',
            brick: 'Baksteen',
            tiles: 'Pannen',
            bitumen_roofing: 'Bitumen dakbedekking',
            slate_shingles: 'Leistenen dakpan',
            black_concrete_tiles: 'Zwarte betontegels',
            thatched: 'Rieten dak',
            asbestos: 'Asbest',
            home_liberal_profession_or_office: 'Huis, vrij beroep of kantoor',
            home_other_professional_activity: 'Andere beroepsactiviteit',
            home: 'Huis',
            no_economic_activity: 'Geen economische activiteit',
            activity_unlikely: 'Activiteit onwaarschijnlijk',
            activity_possible: 'Activiteit mogelijk',
            activity_likely: 'Activiteit waarschijnlijk',
            activity_very_likely: 'Activiteit zeer waarschijnlijk',
            n: 'N',
            ne: 'NO',
            e: 'O',
            se: 'ZO',
            s: 'Z',
            sw: 'ZW',
            w: 'W',
            nw: 'NW',
            limited: 'Beperkt',
            details_previous_page: '(details op vorige pagina)',
            low: 'Laag',
            medium: 'Gemiddeld',
            high: 'Hoog',
            unknown: 'Onbekend',
            city: 'Stad',
            suburbs: 'Stedelijk',
            village: 'Dorp',
            isolated: 'Ge\u00efsoleerd',
            none: 'Geen',
            classified_facade: 'Beschermde gevel',
            green_neighbourhood: 'Groene buurt',
            sea_view: 'Zeezicht',
            no_direct_traffic: 'Geen direct verkeer',
            exceptional_view: 'Uitzonderlijk zicht',
            tram: 'Tram',
            train: 'Trein',
            metro: 'Metro',
            traffic: 'Verkeer',
            busy_area: 'Drukke omgeving',
            abex: 'ABEX',
        },
        request: {
            valuation_request_ref: 'Waarderingsreferentie',
            bank: 'Bank',
            customer: 'Klant',
            purpose: 'Doel van waardering',
        },
        footer: {
            text: 'Voor meer informatie, bezoek {url.rockestate_main}',
        },
        url: {
            rockestate_main: 'https://www.rockestate.io',
            dashboard: 'https://ing.rock.estate/valuation/be-NL/dashboard',
            rockestate_info: 'https://www.rockestate.io/',
            valuation_app: 'https://avm-dev.rock.estate/valuation/be-NL/',
        },
        export: {
            by: 'Uitgevoerd door',
            date: 'Datum',
            reference: 'Referentie',
        },
        section: {
            reconstruction_value: 'Heropbouw',
            address_location: 'Adres en locatie',
            secondary_buildings: 'Bijgebouwen',
            economic_activity: 'Economische activiteit',
            roof: 'Dak',
            warnings: 'Waarschuwingen',
            general_info: 'Algemene informatie',
            parcels_and_buildings: 'Percelen en gebouwen',
            c_parcels: 'Grond',
            c_building: 'Hoofdgebouw',
            building: 'Gebouw',
            building_structure: 'Structuur van het gebouw',
            building_purpose_classification: 'Bestemming en klassering van het gebouw',
            parcels: 'Perceel',
            surroundings: 'Directe omgeving',
            valuations: 'Waarderingen',
            reference_properties_location: 'Referentiepanden: locatie',
            reference_properties_characteristics: 'Referentiepanden: kenmerken',
            flood: 'Overstromingsgebieden',
            faq: 'Veelgestelde vragen',
            questions_feedback: 'Vragen en feedback',
            contact_info: 'Contactgegevens',
            disclaimer: 'Disclaimer',
        },
        front: {
            title: 'Waarderingsverslag',
            core_title: 'Eigendomsrapport ',
        },
        disclaimer: {
            dvm:
                'Dit waarderingsverslag is het resultaat van een evaluatie op afstand van het pand door een vastgoedschatter. Deze persoon heeft het pand niet bezocht, maar heeft op basis van diverse beschikbare data en een statistisch model een onafhankelijke waardering van het pand gemaakt. Voor meer informatie over deze waarderingsmethode verwijzen wij u naar de laatste pagina van dit rapport.',
            ovm:
                'Dit waarderingsverslag is het resultaat van een evaluatie van het pand gemaakt ter plaatse door een vastgoedexpert. Deze onafhankelijke waardering werd gemaakt op basis van de geobserveerde situatie van het pand door de expert, en wordt verder ondersteund door een statistisch model. Voor meer informatie over deze waarderingsmethode verwijzen wij u naar de laatste pagina van dit verslag.',
            part_1:
                'Deze evaluatie werd uitgevoerd voor het hierbij aangegeven doel. Er wordt geen verantwoordelijkheid aanvaard voor het geval dat dit rapport wordt gebruikt voor een ander doel of door een andere partij dan de partij tot wie het is gericht. Indien een andere partij dit rapport wenst te gebruiken, dient zij vooraf de schriftelijke toestemming van Rockestate te krijgen.\n',
            part_2:
                'De publicatie van dit waarderingsverslag, in zijn geheel of gedeeltelijk, is verboden zonder de schriftelijke toestemming van Rockestate. Het auteursrecht van dit rapport blijft te allen tijde eigendom van Rockestate. Dit rapport mag niet worden overgedragen aan een derde partij zonder de expliciete toestemming van de eigenaar van het copyright.',
            part_3:
                'Dit waarderingsverslag spreekt zich niet uit over, noch aanvaardt het enige verantwoordelijkheid voor, het (on)vermogen en de (on)wil van de hypotheeknemer om zijn verplichtingen in het kader van de voorgestelde lening na te komen.',
        },
        features: {
            f_building_type: 'Type woning',
            f_number_of_facades: 'Aantal gevels',
            f_parcel_area: 'Totale perceeloppervlakte',
            f_building_area: 'Oppervlakte aan de grond van het hoofdgebouw',
            f_x_annexes: 'Bijgebouwen',
            f_annexes_area: 'Totale oppervlakte van bijgebouwen',
            f_area_largest_annex: 'Grootste bijgebouw',
            parcel_ids: 'Kadastrale referenties',
            f_lng: 'Lengtegraad',
            f_lat: 'Breedtegraad',
            f_number_of_addresses: 'Aantal adressen',
            f_living_area: 'Bewoonbare oppervlakte',
            f_construction_year: 'Bouwjaar',
            f_epc: '',
            f_epc_label: '',
            f_epc_numeric: 'EPC',
            f_top_floor: 'Bovenste verdieping van het gebouw',
            f_floors: 'Verdieping(en) van het appartement',
            f_balcony_present: 'Terras',
            f_n_closed_garage: 'Aantal gesloten garages',
            f_n_parking_spot: 'Aantal parkeerplaatsen',
            f_cellar_attic: 'Kelder en/of zolder',
            f_garden_common: 'Toegang tot een gemeenschappelijke tuin',
            f_garden_private: 'Toegang tot een privatieve tuin',
            f_volume: 'Volume hoofdgebouw',
            f_roof_area: 'Dakoppervlakte',
            f_mean_tilt: 'Gemiddelde dakhelling',
            f_percent_of_roof_flat: '% plat dak',
            f_min_height: 'Minimale dakhoogte',
            f_max_height: 'Maximale dakhoogte',
            f_n_parts: 'Delen van het hoofdgebouw',
            f_n_roof_panes: 'Dakpannen',
            f_x_matched_entities: 'Entiteiten bekend op dit adres',
            c_economic_activity_class: 'Soort economische activiteit',
            c_economic_activity_level: 'Niveau van economische activiteit',
            f_garden_area: 'Tuinoppervlak',
            f_swimming_pool: 'Buitenzwembad',
            f_swimming_pool_area: 'Grootte buitenzwembad',
            f_distance_to_street: 'Afstand tot midden van straat',
            f_dist_building_flood: 'Dichtstbijzijnd overstromingsgebied',
            f_dist_building_flood_insurance_exclusion:
                'Dichtstbijzijnd overstromingsgebied\n(risicozones)',
            f_flood_risk: 'Overstromingsrisico',
            f_flood_risk_insurance_exclusion: 'Overstromingsrisico\n(risicozones)',
            f_flood_risk_composite: 'Samengesteld overstromingsrisico',
            level: 'Informatieniveau',
            f_ground_height_above_sea: 'Hoogte (boven zeespiegel)',
        },
        info: {
            data_from_customer: 'data verkregen van de klant',
        },
        dvm_features: {
            style_of_house: 'Stijl van gebouw',
            exterior_state: 'Staat van het pand aan de buitenkant',
            facade_material: 'Gevelmateriaal',
            roof_material: 'Materiaal dak',
            n_roof_windows: 'Aantal dakvensters',
            n_roof_dormers: 'Aantal dakkapellen',
            solar_panel_area: 'Zonnepanelen oppervlakte',
            f_building_listed: 'Erfgoed',
            f_building_protected: 'Klassificatie',
            garden_orientation: 'Ori\u00ebntatie van de tuin',
            garden_arrangement: '(Mogelijke) structuur van de tuin',
            direct_traffic_level: 'Niveau van direct straatverkeer',
            indirect_traffic_level: 'Nabijheid van indirect straatverkeer',
            surroundings: 'Beschrijving van de omgeving',
            other_positive:
                'Andere elementen die een positieve invloed hebben op de vastgoedwaardering',
            other_negative:
                'Andere elementen die een negatieve invloed hebben op de vastgoedwaardering',
            remarks: 'Opmerkingen',
        },
        '': {
            'clarify here': 'Economische activiteit',
        },
        fixed: {
            parcels_and_building_shapes: 'Vorm van perceel en gebouw',
        },
        reference_properties_location: {
            explanation_1:
                'De waardering van dit pand is gebaseerd op 200 referentiepanden in de buurt. Elk referentiepand deelt een aantal elementen inzake ligging, grootte en andere vergelijkbare kenmerken. Het onderliggende statistische model van deze waardering is gebaseerd op een "Geographically Weighted Regression" (GWR), waarbij referentiepunten in de onmiddellijke nabijheid van het pand een grotere gewicht krijgen in vergelijking met referentiepunten die zich verderaf bevinden.',
            explanation_2:
                'De onderstaande kaart geeft een overzicht van de locatie van de referentiepanden en de afstand tot het te evalueren pand. De kleurenlegende geeft een goede indicatie van de gemiddelde marktwaarde van woningen in uw omgeving.',
        },
        reference_properties_characteristics: {
            explanation_1:
                'Een pand heeft diverse objectieve kenmerken die bijdragen aan de prijs zoals locatie, grootte en energie-effici\u00ebntie. De onderstaande grafieken vergelijken een aantal van deze kenmerken van deze woning met de andere referentiepanden in de buurt.',
            property_characteristics: 'Kenmerken pand',
            properties_in_the_area: 'Referentiepanden in uw buurt',
        },
        flood: {
            explanation_1:
                "Overzicht van de offici\u00eble overstromingsgebieden in de nabijheid van de woning. Er worden verschillende risicocategorie\u00ebn berekend op basis van verschillende overstromingsrisicoscenario's, vari\u00ebrend van zeer laag tot hoog.",
        },
        faq: {
            q_incorrect_valuation:
                'Het adres van de gewaardeerde woning is onjuist, wat moet ik doen?',
            a_incorrect_valuation:
                'Misschien ging er iets mis bij het selecteren van het adres, het gebouw of de percelen die bij het pand horen. U kunt de adresgegevens bekijken via de volgende link: {url.dashboard}.\u2028',
            q_incorrect_characteristics:
                'Het adres van het pand is juist, maar andere kenmerken in dit waarderingsverslag zijn niet correct, wat moet ik doen?',
            a_incorrect_characteristics:
                'Indien u denkt dat bepaalde informatie in dit verslag substantieel kunnen verschillen, en dit zou kunnen leiden tot een belangrijke impact op de waardering van het onroerend goed, volg dan deze link {url.dashboard} om de onjuiste/ontbrekende informatie te controleren en te verduidelijken.',
            q_transaction_vs_market_value:
                'Waarom verschilt de transactieprijs die ik heb betaald van de marktwaarde die in dit verslag wordt bepaald?',
            a_transaction_vs_market_value_dvm:
                'Dit waarderingsverslag is het resultaat van de waardering van het pand in kwestie, uitgevoerd door een vastgoedschatter op afstand, en ondersteund door een statistisch model. Het verslag geeft een nauwkeurige indicatie van de geschatte waarde van het pand, op basis van de beschikbare informatie. Aangezien de schatter het onroerend goed niet effectief heeft bezocht, kan het gebeuren dat de impact van bepaalde, specifieke kenmerken van het onroerend goed, niet volledig tot uiting komt in de geschatte transactieprijs. Neem een kijkje op onze website voor meer informatie: {url.rockestate_info}.',
            a_transaction_vs_market_value_ovm:
                'Dit waarderingsverslag is het resultaat van de evaluatie, door een vastgoedexpert, op afstand en ondersteund door een statistisch model. Het verslag geeft een nauwkeurige indicatie van de geschatte waarde van het pand, op basis van de beschikbare informatie. Aangezien de expert het onroerend goed niet effectief heeft bezocht, kan het gebeuren dat de impact van bepaalde, specifieke kenmerken van het onroerend goed, niet volledig tot uiting komt in de geschatte transactieprijs. Neem een kijkje op onze website voor meer informatie: {url.rockestate_info}.',
            q_loan_accepted:
                'Nu ik mijn waarderingsverslag heb ontvangen, betekent dit dat mijn kredietaanvraag is goedgekeurd?',
            a_loan_accepted:
                'Wij hebben dit waarderingsverslag met u en uw bank gedeeld. Dit verslag zal door de bank worden gebruikt als een van de verschillende elementen in haar beslissingsproces. De uiteindelijke beslissing komt toe aan de bank. Rockestate komt hier op geen enkele manier in tussen.',
        },
        questions_feedback: {
            explanation_1:
                'Heeft u een specifieke vraag of opmerking over dit rapport? Of bent u gewoon op zoek naar wat meer informatie over hoe Rockestate tot deze waardering komt?',
            explanation_2: 'Bezoek ons waarderingsportaal: {url.valuation_app}.',
        },
    },
    'be-EN': {
        unit: {
            m: 'm',
            m2: 'm\u00b2',
            m3: 'm\u00b3',
            'kwh/m2year': 'kWh/m\u00b2yr',
        },
        address_text: {
            full_address: 'Address',
        },
        valuation: {
            date: 'Valuation date',
            type: 'Valuation type',
            valuer: 'Valuer',
            market_value: 'Market value',
            forced_sale_value: 'Forced sale value',
            rental_value: 'Monthly rental value',
            reconstruction_value: 'Reconstruction value',
            index_value: 'Index',
            index_type: 'Index type',
            explanation_1:
                'The valuation of this property relies in part on a data-driven statistical model. Its result has been reviewed and fine-tuned by a real estate expert. This is a two-step process:',
            explanation_2: 'Step 1: Estimate by valuation model',
            explanation_3:
                'An initial estimate of the property\u2019s Market value is calculated by the model based on all Customer input and some of the other input parameters (cf. page 2). This valuation represents the average market value for a property with this location and characteristics.',
            explanation_4: 'Step 2: Review and fine-tuning by real estate expert',
            explanation_5:
                "The real estate expert supervises the model valuation in Step 1 and subsequently factors in the remaining input parameters. This results in the Market value of the property (red line in graph). Depending on the corrections applied by the real estate expert, the Market value can be lower or higher compared to the initial estimate of the valuation model.\n\nThe chart below illustrates how the Market value of this property compares to other properties in the neighbourhoud. It always contextualizes the expert's review.",
            explanation_6:
                'The Market value in this report is based on an expert-reviewed desktop valuation and attempts to give the best possible view on the property\u2019s current value. However, it remains an estimation where the following elements need to be taken into account:\n- Remote valuation: the expert has evaluated the property remotely and did not actually visit the property.\n- Model accuracy: the valuation relies, at least partially, on a statistical model which has a certain margin of error.\n- Level of subjectivity: even the best expert valuation is somewhat biased by a level of subjectivity.',
        },
        value: {
            avm: 'Automatic valuation',
            dvm: 'Remote valuation',
            ovm: 'On-site valuation',
            new_loan: 'New loan application',
            revaluation: 'Revaluation',
            house: 'House',
            apartment: 'Apartment',
            annex: 'Annex',
            attached: 'Attached',
            semi: 'Semi detached',
            detached: 'Detached',
            yes: 'Yes',
            no: 'No',
            traditional: 'Traditional',
            modern: 'Modern',
            contemporary: 'Contemporary',
            mediterranean: 'Mediterranean',
            other: 'Other',
            maintenance_required: 'Maintenance required',
            good: 'Good',
            very_good: 'Very good',
            cinder_blocks: 'Cinder blocks',
            wood: 'Wood',
            plaster: 'Plaster',
            natural_stone: 'Natural stone',
            metal: 'Metal',
            concrete: 'Concrete',
            brick: 'Brick',
            tiles: 'Tiles',
            bitumen_roofing: 'Bitumen roofing',
            slate_shingles: 'Slate shingles',
            black_concrete_tiles: 'Black concrete tiles',
            thatched: 'Thatched roof',
            asbestos: 'Asbestos',
            home_liberal_profession_or_office: 'Home, liberal profession or office',
            home_other_professional_activity: 'Other professional activity',
            home: 'Home',
            no_economic_activity: 'No economic activity',
            activity_unlikely: 'Activity unlikely',
            activity_possible: 'Activity possible',
            activity_likely: 'Activity likely',
            activity_very_likely: 'Activity very likely',
            n: 'N',
            ne: 'NE',
            e: 'E',
            se: 'SE',
            s: 'S',
            sw: 'SW',
            w: 'W',
            nw: 'NW',
            limited: 'Limited',
            details_previous_page: '(details on previous page)',
            low: 'Low',
            medium: 'Medium',
            high: 'High',
            unknown: 'Unknown',
            city: 'City',
            suburbs: 'Suburbs',
            village: 'Village',
            isolated: 'Isolated',
            none: 'None',
            classified_facade: 'Classified facade',
            green_neighbourhood: 'Green neighbourhood',
            sea_view: 'Sea view',
            no_direct_traffic: 'No direct traffic',
            exceptional_view: 'Exceptional view',
            tram: 'Tram',
            train: 'Train',
            metro: 'Metro',
            traffic: 'Traffic',
            busy_area: 'Busy area',
            abex: 'ABEX',
        },
        request: {
            valuation_request_ref: 'Valuation reference',
            bank: 'Bank',
            customer: 'Customer',
            purpose: 'Valuation purpose',
        },
        footer: {
            text: 'For more information, check out {url.rockestate_main}',
        },
        url: {
            rockestate_main: 'https://www.rockestate.io',
            dashboard: 'https://ing.rock.estate/valuation/default/dashboard',
            rockestate_info: 'https://www.rockestate.io/',
            valuation_app: 'https://ing.rock.estate/valuation/be-EN/',
        },
        export: {
            by: 'Exported by',
            date: 'Date',
            reference: 'Reference',
        },
        section: {
            reconstruction_value: 'Reconstruction',
            address_location: 'Address and location',
            secondary_buildings: 'Secondary buildings',
            economic_activity: 'Economic activity',
            roof: 'Roof',
            warnings: 'Warnings',
            general_info: 'General info',
            parcels_and_buildings: 'Parcels and buildings',
            c_parcels: 'Land',
            c_building: 'Main building',
            building: 'Building',
            building_structure: 'Building structure',
            building_purpose_classification: 'Building purpose & classification',
            parcels: 'Parcel(s)',
            surroundings: 'Surroundings',
            valuations: 'Valuations',
            reference_properties_location: 'Reference properties: location',
            reference_properties_characteristics: 'Reference properties: characteristics',
            flood: 'Flood zones',
            faq: 'Frequently asked questions',
            questions_feedback: 'Questions and feedback',
            contact_info: 'Contact information',
            disclaimer: 'Disclaimer',
        },
        front: {
            title: 'Property valuation report',
            core_title: 'Property report',
        },
        disclaimer: {
            dvm:
                'This valuation report is the result of a remote assessment of the property by a real estate valuer. The valuer did not visit the property but relied on various data sources and a statistical model to make an independent valuation of the property. For more information regarding this valuation approach, please refer to the final page of this report.',
            ovm:
                'This valuation report is the result of an on-site assessment of the property by a real estate expert. The independent valuation has been based on the property situation observed by the expert, and is further supported by a statistical model. For more information regarding this valuation approach, please refer to the final page of this report.',
            part_1:
                'This valuation has been completed for the purpose expressed herein, no responsibility is accepted in the event of this report being used for any other purpose or by any other party than to whom it is addressed. Should any other party wish to use or rely upon this report for any purpose they must first obtain our written consent.\n',
            part_2:
                'The publication of this report, in whole or in part, is prohibited without the written approval of Rockestate. The copyright of this report at all times remains the property of Rockestate. This report may not be assigned to a third party without the express consent of the owner of the copyright.',
            part_3:
                'In making this valuation report we express no opinion as to, and accept no liability for, the ability and willingness of the proposed mortgagor to meet his/her/its commitments under the proposed loan.',
        },
        features: {
            f_building_type: 'Building type',
            f_number_of_facades: 'Number of facades',
            f_parcel_area: 'Total parcel area',
            f_building_area: 'Building footprint area',
            f_x_annexes: 'Secondary buildings',
            f_annexes_area: 'Total area of secondary buildings',
            f_area_largest_annex: 'Largest secondary building',
            parcel_ids: 'Cadastral references',
            f_lng: 'Longitude',
            f_lat: 'Latitude',
            f_number_of_addresses: 'Number of addresses',
            f_living_area: 'Living area',
            f_construction_year: 'Construction year',
            f_epc: '',
            f_epc_label: '',
            f_epc_numeric: 'EPC',
            f_top_floor: 'Top floor of the building',
            f_floors: 'Floor(s) of the apartment',
            f_balcony_present: 'Terrace',
            f_n_closed_garage: 'Number of closed garages',
            f_n_parking_spot: 'Number of parking spots',
            f_cellar_attic: 'Cellar and/or attic',
            f_garden_common: 'Access to a common garden',
            f_garden_private: 'Access to a private garden',
            f_volume: 'Main building volume',
            f_roof_area: 'Roof area',
            f_mean_tilt: 'Mean roof angle',
            f_percent_of_roof_flat: 'Flat roof proportion',
            f_min_height: 'Minimum roof height',
            f_max_height: 'Maximum roof height',
            f_n_parts: 'Parts of the main building',
            f_n_roof_panes: 'Roof panes',
            f_x_matched_entities: 'Known entities at this address',
            c_economic_activity_class: 'Economic activity class',
            c_economic_activity_level: 'Economic activity level',
            f_garden_area: 'Garden area',
            f_swimming_pool: 'Exterior swimming pool',
            f_swimming_pool_area: 'Exterior swimming pool size',
            f_distance_to_street: 'Distance to middle street',
            f_dist_building_flood: 'Nearest flood zone',
            f_dist_building_flood_insurance_exclusion:
                'Nearest flood zone\n(insurance exclusion)',
            f_flood_risk: 'Flood risk',
            f_flood_risk_insurance_exclusion: 'Flood risk\n(insurance exclusion)',
            f_flood_risk_composite: 'Composite flood risk',
            level: 'Level of information',
            f_ground_height_above_sea: 'Altitude (above sea level)',
        },
        info: {
            data_from_customer: 'data input by Customer',
        },
        dvm_features: {
            style_of_house: 'Building style',
            exterior_state: 'Exterior state of the property',
            facade_material: 'Facade material',
            roof_material: 'Roof material',
            n_roof_windows: 'Number of roof windows',
            n_roof_dormers: 'Number of dormers',
            solar_panel_area: 'Solar panel area',
            f_building_listed: 'Listed for historical purposes',
            f_building_protected: 'Classification',
            garden_orientation: 'Orientation of garden',
            garden_arrangement: '(Potential of) garden arrangement',
            direct_traffic_level: 'Level of direct street traffic',
            indirect_traffic_level: 'Proximity of indirect street traffic',
            surroundings: 'Description of the surroundings',
            other_positive: 'Other elements positively impacting the property valuation',
            other_negative: 'Other elements negatively impacting the property valuation',
            remarks: 'Remarks',
        },
        '': {
            'clarify here': 'Economic activity',
        },
        fixed: {
            parcels_and_building_shapes: 'Parcel and building shapes',
        },
        reference_properties_location: {
            explanation_1:
                'The valuation of this property relies on 200 reference properties which have been selected in the property\u2019s neighbourhood. Each reference property shares a number of similarities in terms of location, size and other characteristics with the property under valuation. The underlying statistical model of this valuation is based on a Geographically Weighted Regression (GWR) where reference properties in the immediate proximity of the valued property receive a larger weighting compared to reference properties which are located further away.',
            explanation_2:
                'The map below gives on overview of the location of the reference properties and their respective distance versus the valued property. The color gradient on the map gives a good indication of the average market value of properties in the area.',
        },
        reference_properties_characteristics: {
            explanation_1:
                'A property has various objective characteristics that contribute to its price, such as location, size and energy efficiency. The charts below compare some of these characteristics of this property with those of the reference properties.',
            property_characteristics: 'Property characteristics',
            properties_in_the_area: 'Properties in the area',
        },
        flood: {
            explanation_1:
                'Overview of the official flood zones in the proximity of the property. Several categories of flood risk are calculated according to different flood risk scenarios, varying from Very Low to High.',
        },
        faq: {
            q_incorrect_valuation:
                'The address of the valued property is incorrect, what should I do?',
            a_incorrect_valuation:
                'It seems that something must have gone wrong when selecting the address, the building or the parcels related to the property. You can review the address information via the following link: {url.dashboard}.',
            q_incorrect_characteristics:
                'The address of the valued property is correct, but some of the other characteristics in this valuation report are incorrect, what should I do?',
            a_incorrect_characteristics:
                'If you believe that some information  in this report may substantially differ from the actual situation, which could lead to a material impact on the valuation of the property, follow this link {url.dashboard} to review and clarify the incorrect/missing information.',
            q_transaction_vs_market_value:
                'Why is the transaction price I paid different from the market value determined in this report?',
            a_transaction_vs_market_value_dvm:
                'This valuation report is the result of the remote assessment of the property in question by a real estate expert, supported by a data-driven statistical model. The report should give an accurate indication of the expected value of the property, based on the information at hand. Since the valuer did not actually visit the property, it can happen that the impact of certain, specific, property characteristics has not been fully reflected in the estimated transaction price. Please have a look at our information page via the following link: {url.rockestate_info}.',
            a_transaction_vs_market_value_ovm:
                'This valuation report is the result of the assessment of a real estate expert, supported by a data-driven statistical model. The report should give an accurate indication of the expected value of the property, based on the information at hand. Since the valuer did actually visit the property, the impact of certain, specific, property characteristics should be fully reflected in the estimated transaction price. Please have a look at our information page via the following link: {url.rockestate_info}.',
            q_loan_accepted:
                'Now that I have received my valuation report, does that mean my loan application was accepted?',
            a_loan_accepted:
                'We have shared this valuation report with you and your bank. This valuation report will be used by the bank as one of the elements in its credit risk approval process. The final decision remains theirs. Rockestate is not involved in any way in this decision.',
        },
        questions_feedback: {
            explanation_1:
                'Do you have a specific question or comment regarding this report? Or are you just looking for some more information on how Rockestate makes these valuation?',
            explanation_2: 'Please visit our valuation portal: {url.valuation_app}.',
        },
    },
    technical: {
        unit: {
            m: 'unit.m',
            m2: 'unit.m2',
            m3: 'unit.m3',
            'kwh/m2year': 'unit.kwh/m2year',
        },
        address_text: {
            full_address: 'address_text.full_address',
        },
        valuation: {
            date: 'valuation.date',
            type: 'valuation.type',
            valuer: 'valuation.valuer',
            market_value: 'valuation.market_value',
            forced_sale_value: 'valuation.forced_sale_value',
            rental_value: 'valuation.rental_value',
            reconstruction_value: 'valuation.reconstruction_value',
            index_value: 'valuation.index_value',
            index_type: 'valuation.index_type',
            explanation_1: 'valuation.explanation_1',
            explanation_2: 'valuation.explanation_2',
            explanation_3: 'valuation.explanation_3',
            explanation_4: 'valuation.explanation_4',
            explanation_5: 'valuation.explanation_5',
            explanation_6: 'valuation.explanation_6',
        },
        value: {
            avm: 'value.avm',
            dvm: 'value.dvm',
            ovm: 'value.ovm',
            new_loan: 'value.new_loan',
            revaluation: 'value.revaluation',
            house: 'value.house',
            apartment: 'value.apartment',
            annex: 'value.annex',
            attached: 'value.attached',
            semi: 'value.semi',
            detached: 'value.detached',
            yes: 'value.yes',
            no: 'value.no',
            traditional: 'value.traditional',
            modern: 'value.modern',
            contemporary: 'value.contemporary',
            mediterranean: 'value.mediterranean',
            other: 'value.other',
            maintenance_required: 'value.maintenance_required',
            good: 'value.good',
            very_good: 'value.very_good',
            cinder_blocks: 'value.cinder_blocks',
            wood: 'value.wood',
            plaster: 'value.plaster',
            natural_stone: 'value.natural_stone',
            metal: 'value.metal',
            concrete: 'value.concrete',
            brick: 'value.brick',
            tiles: 'value.tiles',
            bitumen_roofing: 'value.bitumen_roofing',
            slate_shingles: 'value.slate_shingles',
            black_concrete_tiles: 'value.black_concrete_tiles',
            thatched: 'value.thatched',
            asbestos: 'value.asbestos',
            home_liberal_profession_or_office: 'value.home_liberal_profession_or_office',
            home_other_professional_activity: 'value.home_other_professional_activity',
            home: 'value.home',
            no_economic_activity: 'value.no_economic_activity',
            activity_unlikely: 'value.activity_unlikely',
            activity_possible: 'value.activity_possible',
            activity_likely: 'value.activity_likely',
            activity_very_likely: 'value.activity_very_likely',
            n: 'value.n',
            ne: 'value.ne',
            e: 'value.e',
            se: 'value.se',
            s: 'value.s',
            sw: 'value.sw',
            w: 'value.w',
            nw: 'value.nw',
            limited: 'value.limited',
            details_previous_page: 'value.details_previous_page',
            low: 'value.low',
            medium: 'value.medium',
            high: 'value.high',
            unknown: 'value.unknown',
            city: 'value.city',
            suburbs: 'value.suburbs',
            village: 'value.village',
            isolated: 'value.isolated',
            none: 'value.none',
            classified_facade: 'value.classified_facade',
            green_neighbourhood: 'value.green_neighbourhood',
            sea_view: 'value.sea_view',
            no_direct_traffic: 'value.no_direct_traffic',
            exceptional_view: 'value.exceptional_view',
            tram: 'value.tram',
            train: 'value.train',
            metro: 'value.metro',
            traffic: 'value.traffic',
            busy_area: 'value.busy_area',
            abex: 'value.abex',
        },
        request: {
            valuation_request_ref: 'request.valuation_request_ref',
            bank: 'request.bank',
            customer: 'request.customer',
            purpose: 'request.purpose',
        },
        footer: {
            text: 'footer.text',
        },
        url: {
            rockestate_main: 'url.rockestate_main',
            dashboard: 'url.dashboard',
            rockestate_info: 'url.rockestate_info',
            valuation_app: 'url.valuation_app',
        },
        export: {
            by: 'export.by',
            date: 'export.date',
            reference: 'export.reference',
        },
        section: {
            reconstruction_value: 'section.reconstruction_value',
            address_location: 'section.address_location',
            secondary_buildings: 'section.secondary_buildings',
            economic_activity: 'section.economic_activity',
            roof: 'section.roof',
            warnings: 'section.warnings',
            general_info: 'section.general_info',
            parcels_and_buildings: 'section.parcels_and_buildings',
            c_parcels: 'section.c_parcels',
            c_building: 'section.c_building',
            building: 'section.building',
            building_structure: 'section.building_structure',
            building_purpose_classification: 'section.building_purpose_classification',
            parcels: 'section.parcels',
            surroundings: 'section.surroundings',
            valuations: 'section.valuations',
            reference_properties_location: 'section.reference_properties_location',
            reference_properties_characteristics:
                'section.reference_properties_characteristics',
            flood: 'section.flood',
            faq: 'section.faq',
            questions_feedback: 'section.questions_feedback',
            contact_info: 'section.contact_info',
            disclaimer: 'section.disclaimer',
        },
        front: {
            title: 'front.title',
            core_title: 'front.core_title',
        },
        disclaimer: {
            dvm: 'disclaimer.dvm',
            ovm: 'disclaimer.ovm',
            part_1: 'disclaimer.part_1',
            part_2: 'disclaimer.part_2',
            part_3: 'disclaimer.part_3',
        },
        features: {
            f_building_type: 'features.f_building_type',
            f_number_of_facades: 'features.f_number_of_facades',
            f_parcel_area: 'features.f_parcel_area',
            f_building_area: 'features.f_building_area',
            f_x_annexes: 'features.f_x_annexes',
            f_annexes_area: 'features.f_annexes_area',
            f_area_largest_annex: 'features.f_area_largest_annex',
            parcel_ids: 'features.parcel_ids',
            f_lng: 'features.f_lng',
            f_lat: 'features.f_lat',
            f_number_of_addresses: 'features.f_number_of_addresses',
            f_living_area: 'features.f_living_area',
            f_construction_year: 'features.f_construction_year',
            f_epc: 'features.f_epc',
            f_epc_label: 'features.f_epc_label',
            f_epc_numeric: 'features.f_epc_numeric',
            f_top_floor: 'features.f_top_floor',
            f_floors: 'features.f_floors',
            f_balcony_present: 'features.f_balcony_present',
            f_n_closed_garage: 'features.f_n_closed_garage',
            f_n_parking_spot: 'features.f_n_parking_spot',
            f_cellar_attic: 'features.f_cellar_attic',
            f_garden_common: 'features.f_garden_common',
            f_garden_private: 'features.f_garden_private',
            f_volume: 'features.f_volume',
            f_roof_area: 'features.f_roof_area',
            f_mean_tilt: 'features.f_mean_tilt',
            f_percent_of_roof_flat: 'features.f_percent_of_roof_flat',
            f_min_height: 'features.f_min_height',
            f_max_height: 'features.f_max_height',
            f_n_parts: 'features.f_n_parts',
            f_n_roof_panes: 'features.f_n_roof_panes',
            f_x_matched_entities: 'features.f_x_matched_entities',
            c_economic_activity_class: 'features.c_economic_activity_class',
            c_economic_activity_level: 'features.c_economic_activity_level',
            f_garden_area: 'features.f_garden_area',
            f_swimming_pool: 'features.f_swimming_pool',
            f_swimming_pool_area: 'features.f_swimming_pool_area',
            f_distance_to_street: 'features.f_distance_to_street',
            f_dist_building_flood: 'features.f_dist_building_flood',
            f_dist_building_flood_insurance_exclusion:
                'features.f_dist_building_flood_insurance_exclusion',
            f_flood_risk: 'features.f_flood_risk',
            f_flood_risk_insurance_exclusion: 'features.f_flood_risk_insurance_exclusion',
            f_flood_risk_composite: 'features.f_flood_risk_composite',
            level: 'features.level',
            f_ground_height_above_sea: 'features.f_ground_height_above_sea',
        },
        info: {
            data_from_customer: 'info.data_from_customer',
        },
        dvm_features: {
            style_of_house: 'dvm_features.style_of_house',
            exterior_state: 'dvm_features.exterior_state',
            facade_material: 'dvm_features.facade_material',
            roof_material: 'dvm_features.roof_material',
            n_roof_windows: 'dvm_features.n_roof_windows',
            n_roof_dormers: 'dvm_features.n_roof_dormers',
            solar_panel_area: 'dvm_features.solar_panel_area',
            f_building_listed: 'dvm_features.f_building_listed',
            f_building_protected: 'dvm_features.f_building_protected',
            garden_orientation: 'dvm_features.garden_orientation',
            garden_arrangement: 'dvm_features.garden_arrangement',
            direct_traffic_level: 'dvm_features.direct_traffic_level',
            indirect_traffic_level: 'dvm_features.indirect_traffic_level',
            surroundings: 'dvm_features.surroundings',
            other_positive: 'dvm_features.other_positive',
            other_negative: 'dvm_features.other_negative',
            remarks: 'dvm_features.remarks',
        },
        '': {
            'clarify here': '.clarify here',
        },
        fixed: {
            parcels_and_building_shapes: 'fixed.parcels_and_building_shapes',
        },
        reference_properties_location: {
            explanation_1: 'reference_properties_location.explanation_1',
            explanation_2: 'reference_properties_location.explanation_2',
        },
        reference_properties_characteristics: {
            explanation_1: 'reference_properties_characteristics.explanation_1',
            property_characteristics:
                'reference_properties_characteristics.property_characteristics',
            properties_in_the_area:
                'reference_properties_characteristics.properties_in_the_area',
        },
        flood: {
            explanation_1: 'flood.explanation_1',
        },
        faq: {
            q_incorrect_valuation: 'faq.q_incorrect_valuation',
            a_incorrect_valuation: 'faq.a_incorrect_valuation',
            q_incorrect_characteristics: 'faq.q_incorrect_characteristics',
            a_incorrect_characteristics: 'faq.a_incorrect_characteristics',
            q_transaction_vs_market_value: 'faq.q_transaction_vs_market_value',
            a_transaction_vs_market_value_dvm: 'faq.a_transaction_vs_market_value_dvm',
            a_transaction_vs_market_value_ovm: 'faq.a_transaction_vs_market_value_ovm',
            q_loan_accepted: 'faq.q_loan_accepted',
            a_loan_accepted: 'faq.a_loan_accepted',
        },
        questions_feedback: {
            explanation_1: 'questions_feedback.explanation_1',
            explanation_2: 'questions_feedback.explanation_2',
        },
    },
}

<template>
    <div></div>
</template>

<script>
import Rockestate3D from './renderer3d'

export default {
    components: {},
    props: {
        buildingId: {
            type: String,
            default: null,
        },
        solarPotential: {
            type: Boolean,
            default: false,
        },
        autoRotate: {
            type: Boolean,
            default: true,
        },
        showAttributions: {
            type: Boolean,
            default: true,
        },
    },
    data() {
        return {}
    },
    watch: {
        buildingId: {
            immediate: true,
            handler(buildingId, oldBuildingId) {
                if (!this.buildingId) {
                    return
                }
                this.$axios
                    .get(process.env.VUE_APP_API_URL + '/map/scene', {
                        params: {
                            building_id: this.buildingId,
                            solar_potential: this.solarPotential,
                            radius: 40,
                            hide_attributions: !this.showAttributions,
                        },
                    })
                    .then((response) => {
                        Rockestate3D.attach(this.$el)
                        Rockestate3D.autoRotate = this.autoRotate
                        Rockestate3D.build_neighbourhood(response.data)
                    })
                    .catch((error) => console.log(error))
            },
        },
    },
    mounted() {
        Rockestate3D.api_url = process.env.VUE_APP_API_URL
        Rockestate3D.attribution_text = this.showAttributions ? 'Source: ' : ''
        Rockestate3D.attach(this.$el)
    },
    methods: {},
}
</script>

<style lang="scss" scoped>
div {
    height: 100%;
    width: 100%;
}
</style>

import Vue from 'vue'
import Router from 'vue-router'
import email from '@/shared/user'
import Home from '@/views/Home.vue'
import House from '@/views/House.vue'
import Login from '@/shared/components/Login.vue'
import Report from '@/../../Valuation/src/components/report/BuildingReport.vue'
import store from './store/index.js'
import i18n from '@/shared/i18n'

Vue.use(Router)

// get lang from route
const lang = localStorage.getItem('lang') || 'be-EN'

let loadQueryString = async (to) => {
    let query = Object.assign(
        {
            building_id: null,
            streetname: null,
            streetnumber: null,
            postalcode: null,
            municipality: null,
            gsv_pp: null,
            gsv_ph: null,
            gsv_pz: null,
            gsv_p: null,
        },
        to.query
    )
    if (query.postalcode) {
        store.commit('UPDATE_ADDRESS', {
            streetname: query.streetname,
            streetnumber: query.streetnumber,
            postalcode: query.postalcode,
            municipality: query.municipality,
        })
    }
    if (query.gsv_p) {
        store.commit('UPDATE_VIEW', {
            gsv_pp: query.gsv_pp,
            gsv_ph: query.gsv_ph,
            gsv_pz: query.gsv_pz,
            gsv_p: query.gsv_p,
        })
    }
    if (query.building_id) {
        await store.dispatch('updateBuildingId', query.building_id)
    }
}

let beforeEnter = async (to, from, next) => {
    const langs = ['be-NL', 'be-FR', 'be-EN']
    // if correct lang update localStorage and update lang variable
    if (langs.includes(to.params.lang)) {
        localStorage.setItem('lang', to.params.lang)
        i18n.locale = to.params.lang
    }

    const actionList = ['3d', '2d', 'streetview']
    const action_exists = actionList.includes(to.params.action)
    const check_login = process.env.VUE_APP_CHECK_LOGIN.toLowerCase() === 'true'
    if (check_login && store.state.auth.authenticated == null) {
        await store.dispatch('check_authentication')
    }
    let logged_in = store.state.auth.authenticated
    if (check_login && logged_in && to.name === 'login') {
        next({ name: 'start', params: { lang: lang } })
    } else if (to.name !== 'login' && check_login && !logged_in) {
        next({ name: 'login', params: { lang: lang } })
    } else if (to.name === 'login' && check_login && !logged_in) {
        next()
    } else if ((to.name === 'action' && action_exists) || to.name === 'report') {
        await loadQueryString(to)
        next()
    } else if (to.name === 'start') {
        next()
    } else {
        next({
            name: check_login && !logged_in ? 'login' : 'start',
            params: { lang: lang },
        })
    }
}

export default new Router({
    mode: 'history',
    base: process.env.VUE_APP_FRONTEND_URL,
    routes: [
        { path: '/', redirect: `/${lang}/start` },
        { path: '/:lang', redirect: '/:lang/start' },
        {
            path: '/default/:action',
            redirect: (to) => {
                let action = to.params.action === undefined ? 'start' : to.params.action
                return { path: `/${lang}/${action}` }
            },
        },
        {
            path: '/:lang/start',
            name: 'start',
            component: Home,
            beforeEnter: beforeEnter,
        },
        {
            path: '/:lang/login',
            name: 'login',
            component: Login,
            beforeEnter: beforeEnter,
        },
        {
            path: '/:lang/report',
            name: 'report',
            component: Report,
            beforeEnter: beforeEnter,
        },
        {
            path: '/:lang/view/:action',
            name: 'action',
            component: House,
            beforeEnter: beforeEnter,
        },
        { path: '*', redirect: `/${lang}/start` },
    ],
})

<template>
    <div class="py-2">
        <div
            class="section-header level has-text-weight-bold has-text-black has-text-left is-size-6"
        >
            <div class="level-left icon-text">
                <img
                    v-if="name !== 'warnings' && icon"
                    class="icon is-medium mr-2"
                    :src="`${publicPath}${icon}.svg`"
                />
                <span
                    v-if="name === 'warnings'"
                    class="icon is-medium mr-2 has-text-warning"
                >
                    <i class="mdi mdi-alert mdi-24px"></i>
                </span>

                <span>{{ $t('section.' + name) }}</span>
            </div>
        </div>
        <div v-if="orientation === 'vertical'">
            <div v-if="columns === 1">
                <section-item
                    v-for="item in items"
                    :key="item.name"
                    :item="item"
                    :orientation="'horizontal'"
                ></section-item>
            </div>
            <div v-if="columns === 2">
                <div class="columns">
                    <div class="column is-6">
                        <section-item
                            v-for="item in items_half_1"
                            :key="item.name"
                            :item="item"
                            :orientation="'horizontal'"
                        ></section-item>
                    </div>
                    <div class="column is-6">
                        <section-item
                            v-for="item in items_half_2"
                            :key="item.name"
                            :item="item"
                            :orientation="'horizontal'"
                        ></section-item>
                    </div>
                </div>
            </div>
        </div>
        <div v-else>
            <div class="columns">
                <section-item
                    v-for="item in items"
                    :key="item.name"
                    :class="horizontal_class"
                    :orientation="'vertical'"
                    :item="item"
                ></section-item>
            </div>
        </div>
    </div>
</template>

<script>
import { config } from './../../assets/config/config'

import SectionItem from './SectionItem.vue'

import { mapGetters } from 'vuex'

export default {
    name: 'Section',
    components: {
        SectionItem,
    },
    props: {
        name: { type: String, default: 'general_info' },
        columns: { type: Number, default: 1 },
        orientation: { type: String, default: 'vertical' },
        filterBuildingType: { type: String, default: null },
        filterNull: { type: Boolean, default: true },
    },
    data() {
        return {
            publicPath: process.env.BASE_URL,
        }
    },
    computed: {
        ...mapGetters(['getFeatures', 'getValuation']),
        items() {
            let filtered_config = config.filter((item) =>
                item.sections.includes(this.name)
            )
            if (this.filterBuildingType) {
                filtered_config = filtered_config.filter((item) =>
                    item.building_type_scope.includes(this.filterBuildingType)
                )
            }
            if (this.filterNull) {
                filtered_config = filtered_config.filter((item) =>
                    item.location === 'features'
                        ? this.getFeatures[item.name]
                        : this.getValuation[item.name]
                )
            }
            return filtered_config
        },
        icon() {
            let section_item = config.filter(
                (item) => (item.location === 'section') & (item.name === this.name)
            )[0]
            if (section_item.icon) {
                return section_item.icon
            } else {
                return null
            }
        },
        items_half_1() {
            let items = this.items
            let half = Math.ceil(items.length / 2)
            return items.slice(0, half)
        },
        items_half_2() {
            let items = this.items
            let half = Math.floor(items.length / 2)
            return items.slice(-half)
        },
        horizontal_class() {
            if (this.items.length == 1) {
                return 'column is-12'
            } else if (this.items.length == 2) {
                return 'column is-6'
            } else if (this.items.length == 3) {
                return 'column is-4'
            } else if (this.items.length == 4) {
                return 'column is-3'
            } else if (this.items.length <= 6) {
                return 'column is-2'
            } else {
                return 'column is-1'
            }
        },
    },
}
</script>

<style lang="scss">
.section-header,
.level:not(:last-child) {
    padding-bottom: 0.5rem;
    margin-bottom: 0.5rem;
    border-width: 0 0 1px 0;
    border-style: solid;
    border-color: rgba(0, 0, 0, 0.1);
}
.section-header {
    margin-bottom: 1rem;
    margin-bottom: 1rem;
}
</style>
